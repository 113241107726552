import { React, useState, useEffect, createContext, useContext } from 'react';
import { Binoculars, CardChecklist, Envelope, Slack, MicrosoftTeams, Tools } from 'react-bootstrap-icons';
import { v4 as uuidv4 } from 'uuid';

import ConfigService from '../../services/config.service';
import ProductSettingsService from '../../services/product.service';
import { AuthContext } from '../../services/auth.service';
import LoadingScreen from '../../components/elements/LoadingScreen';

import "./styles.scss";

import { defaultEventTemplates } from './defaultEventTemplates';
import { transformDataObject } from '../../components/elements/JsonObjTable';

import PageHeader from '../../components/elements/PageHeader';
import { SideMenu } from '../../components/layouts/MainLayout/SideMenu';
import { InteractivePanel } from "../../components/layouts/MainLayout/InteractivePanel";
import { EmailsNotifModal } from '../../components/layouts/MainLayout/SideMenu/EmailNotifModal';
import { SlackNotifModal } from '../../components/layouts/MainLayout/SideMenu/SlackNotifModal';
import { TeamsNotifModal } from '../../components/layouts/MainLayout/SideMenu/TeamsNotifModal';

import {
    ProductSettinsModal,
    DataLayerEventsModal,
    MonitoringModal,
} from "./configModals";

import { useMessage } from "../../components/elements/MessageCenter";

const _ = require('lodash');

const generateUniqueId = () => uuidv4();

export const simplifyDLGEventJson = (template) => {

    const modifyRows = (rows) => {
        const newObj = {};

        if (Array.isArray(rows) && rows.length > 0) {

            rows.forEach((row) => {
                const key = row.key;
                const value = row.value;
                const valueType = row.type;

                if (valueType === "array") {

                    const newNestedObj = modifyRows(value);
                    const itemsList = [];

                    if (typeof newNestedObj === "object" && Object.keys(newNestedObj).length === 0) {
                        newObj[key] = itemsList;
                    } else {
                        itemsList.push(newNestedObj);
                        newObj[key] = itemsList;
                    }


                } else if (valueType === "object") {
                    const newNestedObj = modifyRows(value);
                    newObj[key] = newNestedObj;

                } else {
                    if (!row.exatclyMatch) {
                        newObj[key] = valueType;
                    } else {
                        newObj[key] = value;
                    }

                }
            });
        }

        return newObj;
    };

    const newList = modifyRows(template);
    return newList;
};


const escapeEvents = async (events = {}, mainEvents = {}) => {
    events?.events?.forEach((row) => {
        const eventTemplate = row?.main_templates;
        const simplifiedTemplate = simplifyDLGEventJson(eventTemplate);
        row.templates = simplifiedTemplate;
        delete row.main_templates;
    });
    const updEvents = escape(JSON.stringify(events, 2));

    mainEvents?.events?.forEach((row) => delete row.templates);
    const updMainEvents = escape(JSON.stringify(mainEvents, 2));

    return {
        events: updEvents,
        mainEvents: updMainEvents,
    }
}


// Save function
export const SaveConfigData = async (params) => {
    const {
        companyId,
        currentConfig,
        setCurrentConfig,
        configParams,
        modalSaveFunction,
        handlePanelClick,
        setLoadingActive,
        currentPage,
        setCurrentPage,
        setModalContentCodeControl,
        setSideMenuState,
        success,
        error,
    } = params;

    setLoadingActive(true);

    // Deep clone configParams.templates into events and mainEvents
    const events = JSON.parse(JSON.stringify(configParams?.templates || {}));
    const mainEvents = JSON.parse(JSON.stringify(configParams?.templates || {}));
    const transEvents = JSON.parse(JSON.stringify(configParams?.transaction_templates || {}));
    const mainTransEvents = JSON.parse(JSON.stringify(configParams?.transaction_templates || {}));

    const escapedEvents = await escapeEvents(events, mainEvents);
    const escapedTransactionEvents = await escapeEvents(transEvents, mainTransEvents);

    if (configParams?.templates) {
        configParams.templates = escapedEvents?.events;
        configParams.main_templates = escapedEvents?.mainEvents;
    }

    if (configParams?.transaction_templates) {
        configParams.transaction_templates = escapedTransactionEvents?.events;
        configParams.main_transaction_templates = escapedTransactionEvents?.mainEvents;
    }

    const configObj = { ...currentConfig };

    Object.keys(configParams).forEach(key => configObj[key] = configParams[key]);

    const resp = await modalSaveFunction({ configObj, companyId });
    const respData = await resp?.json() || resp;
    
    switch (respData?.result) {
        case true:
            success("Configuration data successfully saved!");
            setCurrentConfig(configObj);
            break;
        default:
            error();
    }

    switch (currentPage) {
        case "dl_events":
        case "monitoring":
            setModalContentCodeControl(Date.now());
            setSideMenuState(true);
            handlePanelClick(currentPage);
            break;
        default:
            setModalContentCodeControl(null);
            handlePanelClick("empty");
            setCurrentPage("empty");
            setSideMenuState(false);
    }

    setLoadingActive(false);
}

export const ProdSettingsContext = createContext();
export const DataLayerEventsContext = createContext();
export const MonitoringContext = createContext();
export const EmailsNotifContext = createContext();
export const SlackNotifContext = createContext();
export const TeamsNotifContext = createContext();

const productDescLink = `https://docs.code-cube.io/datalayer-guard/intro/`;
const notifImplementationLink = `https://docs.code-cube.io/notifications/`;
const scrapedEventsDescLink = `https://docs.code-cube.io/datalayer-guard/scraped-events/`;
const tagMangerEventsDescLink = `https://docs.code-cube.io/datalayer-guard/events-tag-manager/`;


export const DataLayerGuardConfig = () => {
    const { companyId, companyInfo } = useContext(AuthContext);
    const [currCompanyInfo, setCurrCompanyInfo] = useState(companyInfo);
    
    // Using popup messages
    const { success, warning, error } = useMessage();

    const [sideMenuState, setSideMenuState] = useState(false);
    const [currentModal, setCurrentModal] = useState(<></>);
    const [modalWidth, setModalWidth] = useState("narrow");
    const [currentPage, setCurrentPage] = useState("empty");
    const [modalContentCodeControl, setModalContentCodeControl] = useState(Date.now());

    const [loadingActive, setLoadingActive] = useState(true);

    const [currentConfig, setCurrentConfig] = useState({});
    const [configParams, setConfigParams] = useState({});

    const handlePanelClick = (page) => {
        setSideMenuState(true);
        setCurrentModal(modalFunc[page]);
        setCurrentPage(page);

        switch (page) {
            case "dl_events":
            case "monitoring":
                setModalWidth("wide");
                break;
            default:
                setModalWidth("narrow");
        }
    };

    const [emailNotifAvailable, setEmailNotifAvailable] = useState(false);
    const [slackNotifAvailable, setSlackNotifAvailable] = useState(false);
    const [teamsNotifAvailable, setTeamsNotifAvailable] = useState(false);

    const [timezones, setTimezones] = useState([]);
    const [timezoneName, setTimezoneName] = useState("");
    const [curTimezone, setCurTimezone] = useState(null);
    const [dlgCheckFreq, setDlgCheckFreq] = useState([]);
    const [curCheckFreq, setCurCheckFreq] = useState(null);
    const [companyDomains, setCompanyDomains] = useState([]);

    const [dlEvents, setDlEvents] = useState([]);
    const [newTemplateVersionAvailable, setnewTemplateVersionAvailable] = useState(false);

    const [transactionEvents, setTransactionEvents] = useState([]);
    const [newTransTempVersionAvailable, setNewTransTempVersionAvailable] = useState(false);
    const [databaseName, setDatabaseName] = useState("");

    const [enableEmailNotif, setEnableEmailNotif] = useState(false);
    const [curEmailsList, setCurEmailsList] = useState([]);

    const [enableSlackNotif, setEnableSlackNotif] = useState(false);
    const [slackChannel, setSlackChannel] = useState("");
    const [slackWorkspaceId, setSlackWorkspaceId] = useState("");
    const [slackWebhook, setSlackWebhook] = useState("");

    const [enableTeamsNotif, setEnableTeamsNotif] = useState(false);
    const [teamsChannel, setTeamsChannel] = useState("");
    const [teamsWebhook, setTeamsWebhook] = useState("");

    // Reloading the page content only if the new company profile is different
    useEffect(() => _.isEqual(companyInfo, currCompanyInfo) ? undefined : setCurrCompanyInfo(companyInfo), [companyInfo]);


    // Add ecommerce event button functionality
    const handleTempsPushDefEcomObj = (eventName, idx, eventObj) =>
        pushDefaultEcommerceObject(eventName, idx, eventObj, dlEvents, setDlEvents);

    const handleTransTempsPushDefEcomObj = (eventName, idx, eventObj) =>
        pushDefaultEcommerceObject(eventName, idx, eventObj, transactionEvents, setTransactionEvents);
    
    const handleTempsCleanObj = (idx, eventObj) =>
        cleanJsonObject(idx, eventObj, dlEvents, setDlEvents);

    const handleTransTempsCleanObj = (idx, eventObj) =>
        cleanJsonObject(idx, eventObj, transactionEvents, setTransactionEvents);


    const pushDefaultEcommerceObject = (eventName = "page_load", idx, eventObj, events, setEvents) => {
        const newTemplates = [defaultEventTemplates[eventName]];

        eventObj.templates = JSON.stringify(newTemplates);
        eventObj.main_templates = transformDataObject(newTemplates);
        const newEventsList = [];

        events.forEach(elem => {
            if (elem.uniqueId !== idx) newEventsList.push(elem);
            else newEventsList.push(eventObj);
        });

        setEvents(newEventsList);

        return newTemplates;
    }

    // Clean JSON object
    const cleanJsonObject = (idx, eventObj, events = [], setEvents) => {
        eventObj.templates = "";
        eventObj.main_templates = "";
        const newEventsList = [];

        events.forEach(elem => {
            if (elem.uniqueId !== idx) newEventsList.push(elem);
            else newEventsList.push(eventObj);
        });

        setEvents(newEventsList);

        return [];
    }

    // Add event handle
    const handleTempEventAdd = () => handleEventObjectAdd(dlEvents, setDlEvents);
    const handleTransTempEventAdd = () => handleEventObjectAdd(transactionEvents, setTransactionEvents);

    const handleEventObjectAdd = (events = [], setEvents) => {
        const newEventUniqueId = generateUniqueId();
        
        const newEvent = {
            uniqueId: newEventUniqueId
        };
        const newEventsList = [...events, newEvent];
        
        setEvents(newEventsList);

        return {
            newEvent,
            newEventsList,
            newEventUniqueId,
        };
    }

    // Remove event handle
    const handleTempEventRemove = (idx) => handleEventObjectRemove(dlEvents, setDlEvents, idx);
    const handleTransTempEventRemove = (idx) => handleEventObjectRemove(transactionEvents, setTransactionEvents, idx);

    const handleEventObjectRemove = (events, setEvents, idx) => {
        const newEventsList = [...events].filter((elem) => elem?.uniqueId !== idx ? elem : undefined);
        setEvents(newEventsList);

        return newEventsList;
    }


    useEffect(() => {
        (async () => {
            setLoadingActive(true);

            setSideMenuState(false);

            const resp = await ConfigService.getDataLayerGuardConfigData(companyId);
            const respData = await resp.json();

            setCurrentConfig(respData?.config_data?.current_config);

            // Product settings data
            setTimezones(respData?.config_data?.timezones || []);
            setTimezoneName(respData?.config_data?.current_config.timezone_name || "");
            setCurTimezone(respData?.config_data?.current_config?.timezone || "");
            setDlgCheckFreq(respData?.config_data?.dlg_check_freq || "");
            setCurCheckFreq(respData?.config_data?.current_config?.check_frequency || "");

            // DataLayer Events
            const tempsStr = respData?.config_data?.current_config?.main_templates || "{}";
            const tempEvents = JSON.parse(unescape(tempsStr))?.events;
            setDlEvents(tempEvents);

            setnewTemplateVersionAvailable(() => !!respData?.config_data?.current_config?.main_templates);
            setNewTransTempVersionAvailable(() => !!respData?.config_data?.current_config?.main_transaction_templates);

            // Monitoring via Tag Manager
            const transactionEventsStr = respData?.config_data?.current_config?.main_transaction_templates || "{}";
            const transEvents = JSON.parse(unescape(transactionEventsStr))?.events;
            setTransactionEvents(transEvents);
            setDatabaseName(respData?.config_data?.current_config?.database_name || "");
            setCompanyDomains(respData?.config_data?.current_config?.company_domains || []);

            // Email notifications data
            setEnableEmailNotif(respData?.config_data?.current_config?.email_updates || false);
            setCurEmailsList(respData?.config_data?.current_config?.emails?.split(";") || []);

            // Slack notifications data
            setEnableSlackNotif(respData?.config_data?.current_config?.slack_updates || false);
            setSlackChannel(respData?.config_data?.current_config?.slack_channel || "");
            setSlackWorkspaceId(respData?.config_data?.current_config?.slack_workspace_id || "");
            setSlackWebhook(respData?.config_data?.slack_webhook || "");

            // Teams notifications data
            setEnableTeamsNotif(respData?.config_data?.current_config?.teams_updates || false);
            setTeamsChannel(respData?.config_data?.current_config?.teams_channel || "");
            setTeamsWebhook(respData?.config_data?.current_config?.teams_webhook || "");

            const subLevel = companyInfo?.datalayer_guard_subscription_model?.toLowerCase();
            const settings = await ProductSettingsService.getProductSettings();
            const subSettings = settings?.datalayer_guard_settings[subLevel];
            setEmailNotifAvailable(subSettings?.email_notif);
            setSlackNotifAvailable(subSettings?.slack_notif);
            setTeamsNotifAvailable(subSettings?.teams_notif);

            setLoadingActive(false);
        })();
    }, [currCompanyInfo]);


    const modalFunc = {
        "empty": <></>,
        "general":
            <ProdSettingsContext.Provider
                value={{
                    productDescLink,
                    timezones,
                    timezoneName,
                    curTimezone,
                    dlgCheckFreq,
                    curCheckFreq,
                    setCurTimezone,
                    setCurCheckFreq,
                    setConfigParams,
                    companyDomains,
                    setCompanyDomains
                }}>
                <ProductSettinsModal context={ProdSettingsContext} />
            </ProdSettingsContext.Provider>,
        "dl_events":
            <DataLayerEventsContext.Provider
                value={{
                    companyId,
                    scrapedEventsDescLink,
                    dlEvents,
                    setDlEvents,
                    setConfigParams,
                    companyDomains,
                    newTemplateVersionAvailable,
                    pushDefaultEcommerceObject: handleTempsPushDefEcomObj,
                    cleanJsonObject: handleTempsCleanObj,
                    handleEventAdd: handleTempEventAdd,
                    handleEventRemove: handleTempEventRemove,
                    error,
                }}>
                <DataLayerEventsModal context={DataLayerEventsContext} />
            </DataLayerEventsContext.Provider>,
        "monitoring":
            <MonitoringContext.Provider
                value={{
                    databaseName,
                    companyId,
                    tagMangerEventsDescLink,
                    databaseName,
                    transactionEvents,
                    setTransactionEvents,
                    setConfigParams,
                    companyDomains,
                    newTransTempVersionAvailable,
                    pushDefaultEcommerceObject: handleTransTempsPushDefEcomObj,
                    cleanJsonObject: handleTransTempsCleanObj,
                    handleEventAdd: handleTransTempEventAdd,
                    handleEventRemove: handleTransTempEventRemove,
                    error,
                }}>
                <MonitoringModal context={MonitoringContext} />
            </MonitoringContext.Provider>,

        "email_notif":
            <EmailsNotifContext.Provider value={{
                notifImplementationLink,
                enableEmailNotif: currentConfig?.email_updates || false,
                curEmailsList: currentConfig?.emails?.split(";") || [],
                setConfigParams,
            }}>
                <EmailsNotifModal context={EmailsNotifContext} />
            </EmailsNotifContext.Provider>,
        "slack_notif":
            <SlackNotifContext.Provider value={{
                notifImplementationLink,
                enableSlackNotif: currentConfig?.slack_updates || false,
                slackChannel: currentConfig?.slack_channel || "",
                slackWorkspaceId: currentConfig?.slack_workspace_id || "",
                slackWebhook: currentConfig?.slack_webhook || "",
                setConfigParams,
            }}>
                <SlackNotifModal context={SlackNotifContext} />
            </SlackNotifContext.Provider>,
        "teams_notif":
            <TeamsNotifContext.Provider value={{
                notifImplementationLink,
                enableTeamsNotif: currentConfig?.teams_updates || false,
                teamsChannel: currentConfig?.teams_channel || "",
                teamsWebhook: currentConfig?.teams_webhook || "",
                setConfigParams,
            }}>
                <TeamsNotifModal context={TeamsNotifContext} />
            </TeamsNotifContext.Provider>,
    }

    return (
        <>
            <SideMenu
                key={modalContentCodeControl}
                globalState={sideMenuState}
                setGlobalState={setSideMenuState}
                modalContent={currentModal}
                popoverMessage={<>No changes to save.</>}
                saveFunction={() => SaveConfigData({
                    companyId,
                    currentConfig,
                    setCurrentConfig,
                    configParams,
                    modalSaveFunction: ConfigService.saveDataLayerGuardConfigData,
                    handlePanelClick,
                    setLoadingActive,
                    currentPage,
                    setCurrentPage,
                    setModalContentCodeControl,
                    setSideMenuState,
                    success,
                    error,
                    warning,
                })}
                modalWidth={modalWidth}
            />
            <div className="row">
                <LoadingScreen isActive={loadingActive} />
                <div className="col-12 col-sm-10 col-md-8 text-start">

                    <div>
                        <div className="row">
                            <PageHeader categoryName="configuration" pageName="DataLayer Guard" />
                            <div className="container-rows mt-4">
                                <div>
                                    <InteractivePanel
                                        icon={<Tools size={21} />}
                                        headerText="Product settings"
                                        descriptionText="Basic settings for the configuration of the DataLayer Guard"
                                        available={true}
                                        clickFunction={() => handlePanelClick("general")}
                                    />
                                </div>
                                <div>
                                    <InteractivePanel
                                        icon={<CardChecklist size={21} />}
                                        headerText="Monitoring via scraped events"
                                        descriptionText="Add, edit or remove dataLayer events for monitoring"
                                        available={true}
                                        clickFunction={() => handlePanelClick("dl_events")}
                                    />
                                </div>
                                <div>
                                    <InteractivePanel
                                        icon={<Binoculars size={21} />}
                                        headerText="Monitoring via Tag Manager"
                                        descriptionText="Set-up monitoring via Tag Manager to for example monitor purchases"
                                        available={true}
                                        clickFunction={() => handlePanelClick("monitoring")}
                                    />
                                </div>
                            </div>
                            <h3 style={{ fontSize: '1em' }} className="page-category">NOTIFICATION SETTINGS</h3>
                            <div className="container-rows">
                                <div>
                                    <InteractivePanel
                                        icon={<Envelope size={21} />}
                                        headerText="Email Notifications"
                                        descriptionText="Configure settings to receive notifications per mail"
                                        available={emailNotifAvailable}
                                        clickFunction={() => handlePanelClick("email_notif")}
                                    />
                                </div>
                                <div>
                                    <InteractivePanel
                                        icon={<Slack size={21} />}
                                        headerText="Slack notifications"
                                        descriptionText="Configure settings to receive notifications via Slack"
                                        available={slackNotifAvailable}
                                        clickFunction={() => handlePanelClick("slack_notif")}
                                    />
                                </div>
                                <div>
                                    <InteractivePanel
                                        icon={<MicrosoftTeams size={21} />}
                                        headerText="Teams notifications"
                                        descriptionText="Configure settings to receive notifications via Teams"
                                        available={teamsNotifAvailable}
                                        clickFunction={() => handlePanelClick("teams_notif")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
