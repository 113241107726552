import { React, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../services/auth.service';
import '../assets/cssModules/pages/pages.css';
import { Eye, EyeSlash } from 'react-bootstrap-icons';
import { CompanyLogo } from '../../components/companyLogo';


export const LoginPage = () => {

    const { login } = useContext(AuthContext);
    const navigate = useNavigate();
    let [username, setUsername] = useState("");
    let [password, setPassword] = useState("");
    let [loginResult, setLoginResult] = useState(true);
    const [showPassword, setShowPassword] = useState(false);

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        switch (name) {
            case "username":
                setUsername(value);
                break;
            case "password":
                setPassword(value);
                break;
            default:
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const response = await login({
            "username": username,
            "password": password
        });

        setLoginResult(response?.result);

        if (response?.result) {
            navigate("/");
        }
    };

    return (
        <div className='full-screen-grey-bg pt-5'>
            <div className="col-lg-4 mx-auto text-center pt-4 mt-4 white-bg-frame px-5">
                <CompanyLogo />
                <h1 className='registration-headings'>Sign In</h1>
                {!loginResult ?
                    <div className="alert alert-block alert-danger">
                        <ul className="m-0">
                            <li>Please enter a correct username and password. Note that both fields may be case-sensitive.</li>
                        </ul>
                    </div>
                    : null}
                <form method="POST" className="row mt-5 pt-5 mb-5 registration-forms" onSubmit={handleSubmit}>
                    <div id="div_id_auth-username" className="mb-4">
                        <input
                            type="text"
                            autoFocus=""
                            autoCapitalize="none"
                            autoComplete="username"
                            maxLength="150"
                            className="textinput textInput form-control"
                            required=""
                            id="id_auth-username"
                            placeholder="Email address"
                            name="username"
                            value={username}
                            onChange={handleInputChange} />
                    </div>
                    <div id="div_id_auth-password" className="mb-4">
                        <input
                            autoComplete="current-password"
                            className="textinput textInput form-control"
                            required=""
                            id="id_auth-password"
                            placeholder="Password"
                            name="password"
                            value={password}
                            onChange={handleInputChange}
                            type={
                                showPassword ? "text" : "password"
                            }
                        />
                        <label className='eye-slash-icon' htmlFor="check">
                            {showPassword ? (
                                <Eye />

                            ) : (
                                <EyeSlash />
                            )
                            }

                        </label>
                        <input
                            id="check"
                            type="checkbox"
                            value={showPassword}
                            onChange={() =>
                                setShowPassword((prev) => !prev)
                            }
                        />
                    </div>

                    <div className="row mt-3 pe-0">
                        <div className="d-grid col-12 pe-0">
                            <button type="submit" className="btn blue-btn btn-block">Next</button>
                        </div>
                    </div>
                    <br />
                    <a className="button secondaryAction my-5 col-12 turquoise rounded-button" href="/password-reset">Forgot Password?</a>
                </form>
            </div>
        </div>
    );

}