import React from 'react';
import { Space } from "antd";
import './styles.scss';

const PageHeader = (props) => {
    const {
        categoryName = "",
        pageName = "",
    } = props;

    return (
        <Space direction="vertical" size="small" block="true">
            <h5 className="small-heading">{categoryName.toLowerCase()}</h5>
            <h2 className="header-letter-spacing">{pageName}</h2>
        </Space>
    );
};

export default PageHeader;
