import secureLocalStorage from "react-secure-storage";

class LocalStorageService {
    getToken = () => secureLocalStorage.getItem("token");

    getAdminUser = () => secureLocalStorage.getItem("adminUser");

    getCompanyId = () => parseInt(secureLocalStorage.getItem("companyId"));

    getCompanyList = () => JSON.parse(secureLocalStorage.getItem("companyList"));

    getCompanyInfo = () => JSON.parse(secureLocalStorage.getItem("companyInfo"));

    getUserData = () => JSON.parse(secureLocalStorage.getItem("userData"));

    setToken = (token = "") => secureLocalStorage.setItem("token", token);
    setAdminUser = (adminUser = "") => secureLocalStorage.setItem("adminUser", adminUser);

    setCompanyId = (id = null) => {
        const companiesList = JSON.parse(secureLocalStorage.getItem("companyList") || []);
        const curCompany = companiesList?.find(elem => elem.id === parseInt(id)) || {};
        secureLocalStorage.setItem("companyId", curCompany?.id);
        secureLocalStorage.setItem("companyInfo", JSON.stringify(curCompany));
    }

    setUserData = (data = {}) => secureLocalStorage.setItem("userData", JSON.stringify(data));

    setCompanyList = (list = []) => secureLocalStorage.setItem("companyList", JSON.stringify(list));

    setCompanyInfo = (data = {}) => secureLocalStorage.setItem("companyInfo", JSON.stringify(data));


    getProductSettings = () => JSON.parse(secureLocalStorage.getItem("product_settings"));

    setProductSettings = (object = {}) => secureLocalStorage.setItem("product_settings", JSON.stringify(object));


    clearLocalStorage = () => secureLocalStorage.clear();
}

export default new LocalStorageService();
