import React from 'react';
import { Select, Divider } from 'antd';
import { countries } from 'countries-list';
import * as Flags from 'country-flag-icons/react/3x2';

const { Option } = Select;

const prioritizedCountries = [
    'Netherlands',
    'Belgium',
    'France',
    'Germany',
    'Italy',
    'United Kingdom', // Great Britain is listed as United Kingdom
];

const CountryDropdown = (props) => {
    const {
        value = "",
        onChange = () => {},
        status = "",
    } = props;

    // Separate the prioritized countries
    const prioritizedOptions = Object.entries(countries)
        .filter(([code, country]) => prioritizedCountries.includes(country.name))
        .map(([code, country]) => {
            const FlagComponent = Flags[code];
            return {
                value: country.name,
                label: (
                    <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                        {FlagComponent && (
                            <FlagComponent
                                style={{ width: 20, height: 15, marginRight: 8, display: 'inline-block' }}
                            />
                        )}
                        <span>{country.name}</span>
                    </span>
                ),
            };
        });

    // Separate the rest of the countries
    const otherCountryOptions = Object.entries(countries)
        .filter(([code, country]) => !prioritizedCountries.includes(country.name))
        .map(([code, country]) => {
            const FlagComponent = Flags[code];
            return {
                value: country.name,
                label: (
                    <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                        {FlagComponent && (
                            <FlagComponent
                                style={{ width: 20, height: 15, marginRight: 8, display: 'inline-block' }}
                            />
                        )}
                        <span>{country.name}</span>
                    </span>
                ),
            };
        });

    return (
        <Select
            showSearch
            optionFilterProp="label"
            value={value || "Select a country"}
            status={status}
            onChange={onChange}
            style={{
                width: 185,
            }}
            dropdownAlign={{ points: ['tl', 'bl'] }} // Ensure dropdown opens downwards
            getPopupContainer={(trigger) => trigger.parentNode} // Ensure dropdown opens within the parent container
            filterOption={(input, option) => {
                if (!option.label || !option.label.props || !option.label.props.children) {
                    return false;
                }
                const text = option.label.props.children[1] || '';
                return text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
        >
            {/* Render prioritized countries first */}
            {prioritizedOptions.map((option) => (
                <Option key={option.value} value={option.value} label={option.label}>
                    {option.label}
                </Option>
            ))}
            <Option disabled key="default" value=""><Divider style={{ margin: "10px 0" }} /></Option>
            {/* Render the rest of the countries */}
            {otherCountryOptions.map((option) => (
                <Option key={option.value} value={option.value} label={option.label}>
                    {option.label}
                </Option>
            ))}
        </Select>
    );
};

export default CountryDropdown;
