import React, { useState, useEffect } from 'react';
import { Dropdown, Row, Flex } from 'antd';

import './styles.scss';

const Tabs = (props) => {
    const {
        items = [],
        activeTab = "",
    } = props;

    const [curActiveTab, setCurActiveTab] = useState(activeTab);
    useEffect(() => setCurActiveTab(activeTab), [activeTab]);

    return (
        <Row className="tabs-area">
            <Flex>
                {items?.map(item => {
                    switch (item?.menuItems?.length > 0) {
                        case true:
                            return (
                                <Dropdown key={item.name}
                                    menu={{
                                        items: item?.menuItems,
                                    }}
                                    placement="bottom"
                                    overlayClassName="dropdown-content"
                                >
                                    <div
                                        className={`tab ${curActiveTab === item?.name ? "active" : ""}`}
                                        onClick={() => item?.onClickFunction()}
                                    >
                                        <span className="tab-title no-letter-spacing">
                                            {item?.title}
                                        </span>
                                    </div>
                                </Dropdown>
                            )
                        default:
                            return (
                                <div
                                    key={item.name}
                                    className={`tab ${curActiveTab === item?.name ? "active" : ""}`}
                                    onClick={() => item?.onClickFunction()}
                                >
                                    <span className="tab-title no-letter-spacing">{item?.title}</span>
                                </div>
                            )
                    }
                })}
            </Flex>
        </Row>
    );
};

export default Tabs;
