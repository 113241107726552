import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../services/auth.service";
import localStorageService from "../../services/localStorage.service";

import Sidebar from "../layouts/MainLayout/Sidebar";

import "./styles.scss";

const noAuthPages = [ "password-reset", "change-password", "login" ];
const adminPages = [ "create_dataset", "invoice_settings" ];
const isAuthAlreadyPages = [ "login" ];

/**
 * Checking if user is authenticated
 * 
 * @returns {Boolean} Result of the checkup
 */
const isAuthenticated = () => localStorageService.getToken() !== null;

/**
 * Notification message at the top of the page
 * 
 * @returns {ReactNode} Component with the notification element
 */
const NotifMessage = () => {
    return (
        <div className="alert-area col-8">
            <div className="alert alert-info" role="alert">
                We are migrating to a new version of our portal. 
                Do you encounter any issues? Please notify us 
                <a rel="noreferrer" href="https://code-cube.io/contact-us/" target="_blank"> here.</a>
            </div>
        </div>
    );
}

/**
 * Page access checkup
 * 
 * Checking if the page that user trying to open is available for opening.
 * Just making redirects if user cannot access the page.
 * 
 * @param {Object} props Parameters for the checkup
 * @param {Boolean} [props.adminUser] Is user has admin rights
 * @param {String} [props.pageName] Page name to check
 * @param {Object} props.companyInfo Company info object from auth context
 * @param {Boolean} props.companyInfo.datalayer_guard Company has the DataLayer Guard product available
 * @param {Boolean} props.companyInfo.sitespeed_monitor Company has the Sitespeed Monitor product available
 * @param {Boolean} props.companyInfo.tag_monitor Company has the Tag Monitor product available
 * @param {import('@react-navigation/native').NavigationProp} props.navigate Initialized useNavigate component
 * @returns {null}
 */
const checkPageAccess = (props) => {
    const {
        adminUser = false,
        pageName = "/",
        companyInfo,
        navigate,
    } = props;

    const auth = isAuthenticated();
    const isNoAuthPage = noAuthPages.indexOf(pageName) > -1;
    const isAuthAlreadyPage = isAuthAlreadyPages.indexOf(pageName) > -1;
    const isAdminPage = adminPages.indexOf(pageName) > -1;

    const dlgStatus = companyInfo?.datalayer_guard;
    const ssmStatus = companyInfo?.sitespeed_monitor;
    const tmStatus = companyInfo?.tag_monitor;

    switch (auth) {
        case false:
            if (!isNoAuthPage) navigate("/login");
            break;

        default:
            switch (adminUser) {
                case false:
                    if (isAdminPage) navigate("/");
                    break;

                default:
                    break;
            }

            if (isAuthAlreadyPage) navigate("/");

            switch (pageName) {
                case "datalayer_guard_config":
                case "datalayer_guard_dashboard":
                    if (!dlgStatus) navigate("/");
                    break;
                case "sitespeed_monitor_config":
                    if (!ssmStatus) navigate("/");
                    break;
                case "tag_monitor_config":
                case "tag_monitor_dashboard":
                    if (!tmStatus) navigate("/");
                    break;
                default:
                    break;
            }
    }
}

/**
 * Protected route component
 * 
 * Checking user access to the page before redirecting.
 * 
 * @param {Object} props Page component parameters
 * @param {Object} props.children Children component
 * @param {Object} props.name Name of the page
 * @returns {ReactNode} Page layout component with content
 */
const ProtectedRoute = (props) => {
    const noSidebarPages = [
        "login",
        "logout",
        "password-reset",
        "change-password",
    ];

    const navigate = useNavigate();

    const { children, name } = props;
    const { adminUser, companyInfo } = useContext(AuthContext);

    useEffect(() => {
        checkPageAccess({
            adminUser,
            pageName: name,
            companyInfo,
            navigate,
        });
    }, []);

    const childrenWithProps = React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { AuthContext });
        }
        return child;
    });

    return (
        <>
            {noSidebarPages.indexOf(name) > -1 ?
                <main>
                    {childrenWithProps}
                </main>
                :
                <>
                    <Sidebar context={AuthContext} />
                    <main className="main-content-sidebar">
                        <div className="pt-5 w-100">
                            {childrenWithProps}
                        </div>
                    </main>
                </>
            }
        </>
    );
};

export default ProtectedRoute;
