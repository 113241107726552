import { React, useState } from 'react';
import '../assets/cssModules/pages/pages.css';
import Alert from 'react-bootstrap/Alert';
import { createDataset } from '../../services/dataset.service';
import LoadingScreen from '../../components/elements/LoadingScreen';
import PageHeader from '../../components/elements/PageHeader';

export const CreateDataset = () => {
    const [companyName, setCompanyName] = useState("");
    const [companyId, setCompanyId] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [alertContent, setAlertContent] = useState({});
    const [product, setProduct] = useState("");
    const [loadingActive, SetLoadingActive] = useState(false);

    const handleSelectChange = (event) => setProduct(event.target.value);

    const handleSubmit = async (event) => {
        event.preventDefault();
        SetLoadingActive(true);


        const response = await createDataset({
            "companyName": companyName,
            "companyId": companyId,
            "product": product,
        });

        SetLoadingActive(false);


        if (response?.success && response?.result) {
            setShowAlert(true);
            let alertContent;

            if (!response?.dataset_already_exists) {
                alertContent = {
                    heading: `Dataset ${response?.dataset_name} created successfully!`,
                    variant: "success"
                };
            } else {
                alertContent = {
                    heading: `Dataset ${response?.dataset_name} already exists!`,
                    variant: "warning"
                };
            }

            setAlertContent(alertContent);
        } else if (!response?.success && response?.result && response?.error) {
            setShowAlert(true);
            setAlertContent({
                heading: `An error occurred while creating the dataset: ${response?.error}`,
                variant: "danger"
            });
        }
    };


    return (
        <div>
            <LoadingScreen isActive={loadingActive} />
            <PageHeader categoryName="admin" pageName="Create Datasets" />

            <div className='row'>
                <div className='col-5'>
                    {
                        showAlert &&
                        <Alert variant={alertContent.variant}>
                            {alertContent.heading}
                        </Alert>
                    }
                    <form method="POST" className="row mt-5 mb-5" onSubmit={handleSubmit}>
                        <div id="company_name" className="mb-4">
                            <input
                                type="text"
                                autoFocus=""
                                autoCapitalize="none"
                                autoComplete="company_name"
                                maxLength="150"
                                className="textinput textInput form-control"
                                required={true}
                                id="company_name"
                                placeholder="Company name"
                                name="company_name"
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                            />
                        </div>
                        <div id="company_id" className="mb-4">
                            <label htmlFor='company_id' className='pb-1' style={{ fontSize: '.9em', color: "blue", letterSpacing: .3 }}>
                                Please create the company profile before the
                                dataset and get the company_id from the
                                company profile in the admin panel
                            </label>
                            <input
                                type='number'
                                autoComplete="company_id"
                                className="textinput textInput form-control"
                                required={true}
                                id="company_id"
                                placeholder="Company ID"
                                name="company_id"
                                value={companyId}
                                onChange={(e) => { setCompanyId(e.target.value) }}

                            />
                        </div>

                        <div className='mb-4'>
                            <select
                                className='form-select'
                                value={product}
                                onChange={handleSelectChange}
                            >
                            <option value="">Select product</option>
                            <option value="tag-monitor">Tag Monitor</option>
                            <option value="tag-monitor-premium">Tag Monitor premium</option>
                            <option value="datalayer-guard">DataLayer Guard</option>
                            <option value="sitespeed-monitor">Sitespeed Monitor</option>
                            </select>
                        </div>

                        <div className="row mt-5 pe-0">
                            <div className="d-grid col-4 pe-0">
                                <button type="submit" className="btn blue-btn btn-block">Submit</button>
                            </div>
                        </div>
                        <br />
                    </form>
                </div>
            </div>
        </div>
    )
}
