import React, { useState, useEffect, useContext, useRef } from 'react';
import { CaretDownFill, Clipboard, Calendar3, CaretUpFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';

import localStorageService from '../../services/localStorage.service';
import DashboardService from '../../services/dashboard.service';
import { AuthContext } from '../../services/auth.service';

import PageHeader from '../../components/elements/PageHeader';
import LoadingScreen from '../../components/elements/LoadingScreen';
import Table from '../../components/elements/Table';
import Tabs from '../../components/elements/Tabs';

import { Layout, Row, Col, Flex, Space } from 'antd';

import "./styles.scss";
import { ca, el, tr } from 'date-fns/locale';
import { getDateRangeCode } from '../TagMonitorDash';
import { elements } from 'chart.js';
const { Content } = Layout;

export const DataLayerGuardDash = () => {

    const navigate = useNavigate();

    const { companyId, companyInfo } = useContext(AuthContext);
    const [companyID, setCompanyID] = useState(companyId);
    const [datasetFound, setDatasetFound] = useState(true);
    const [filterButtons, setfilterButtons] = useState(true);
    const [inititalLoad, setInititalLoad] = useState(true);

    const [loadingActive, setLoadingActive] = useState(true);
    const [activeTab, setActiveTab] = useState("errors");

    // Dashboard data
    const [domainsList, setDomainsList] = useState([]);
    const [choosenDomain, setChoosenDomain] = useState("");

    const [errorsList, setErrorsList] = useState([]);
    const [initialErrorsList, setInitialErrorsList] = useState([]);
    const [errorToReview, setErrorToReview] = useState({});
    const [errorExpanded, setErrorExpanded] = useState(false);
    const [issueContent, setIssueContent] = useState(<></>);
    const [eventNameFilter, setEventNameFilter] = useState("");

    const [snapshotsList, setSnapshotsList] = useState([]);
    const [initialSnapshotsList, setInitialSnapshotsList] = useState([]);
    const [snapshotToReview, setSnapshotToReview] = useState({});
    const [snapshotExpanded, setSnapshotExpanded] = useState(false);

    // Tabs
    const [tabsVisibility, setTabsVisibility] = useState({
        "errors": true,
        "overview": false,
    });

    const handleTabsVisibilityChange = (val) => {

        switch (val) {
            case 'errors':
                setTabsVisibility({ "errors": true, "overview": false });
                
                break;
            case 'overview':
                setTabsVisibility({ "errors": false, "overview": true });
                break;
            default:
        }

        setActiveTab(val);
    }

    const handleErrorAreaUpdate = (row) => {

        if (errorToReview === row) {
            setErrorExpanded(!errorExpanded);
        }
        else {
            setErrorExpanded(true);
        }
        setErrorToReview(row);
        const issueContentData = renderIssueContent(row);
        setIssueContent(issueContentData);
    }


    const refreshFilters = () => {

        setfilterButtons(true);
        setChoosenDomain("");
        setEventNameFilter("");
        setStartDate(weekAgoDate);
        setEndDate(curDate);
        setErrorsList(initialErrorsList);
        setSnapshotsList(initialSnapshotsList);
    }


    const renderIssueContent = (row) => {
        const templateString = JSON.stringify(JSON.parse(row?.template || "{}"), null, 4);

        switch (row?.error_type) {

            case "missing_object":
                return (
                    <div key="missing_object" className="error-details-desc">
                        <strong>Example of the expected object</strong>
                        <div className="dl-template-grn">
                            <pre>{templateString}</pre>
                        </div>
                    </div>
                );

            case "incorrect_parameters":
                const description = row?.description;
                const error = row?.error;
                const parameterNames = row?.parameter_name || "";
                const expectedType = row?.expected_type;
                const expectedValue = row?.expected_value;
                const actualType = row?.actual_type;
                const actualValue = row?.actual_value;

                const nameString = (
                    <div>
                        {parameterNames}
                    </div>
                );

                const incorrectTypeErrDesc = (
                    <>
                        <div className="expected-type">
                            <p><strong>Expected type:</strong> {expectedType}</p>
                        </div>
                        <div className="actual-type">
                            <p><strong>Found type:</strong> {actualType}</p>
                        </div>
                        <div className="actual-value">
                            <p><strong>Found value:</strong> {actualValue}</p>
                        </div>
                    </>
                );

                const incorrectValueErrDesc = (
                    <>
                        <div className="expected-value">
                            <p><strong>Expected value:</strong> {expectedValue}</p>
                        </div>
                        <div className="actual-value">
                            <p><strong>Found Value:</strong> {actualValue}</p>
                        </div>
                    </>
                );

                let errorContent = null;

                switch (error) {
                    case "incorrect_type":
                        errorContent = incorrectTypeErrDesc;
                        break;
                    case "incorrect_value":
                        errorContent = incorrectValueErrDesc;
                        break;
                    default:
                }

                return (
                    <div key="incorrect_parameters" className="error-details-desc">
                        <h6><strong>Issue</strong></h6>
                        <div className="param-error">
                            <strong>Error:</strong> {description}
                        </div>
                        {nameString &&
                            <div className="parameters"><strong>Parameter:</strong>
                                {nameString}
                            </div>
                        }
                        {errorContent &&
                            <div>
                                {errorContent}
                            </div>
                        }
                    </div>
                );

            default:
                return (<></>);
        }

    }

    const dataLayerContentPreview = (datalayerString) => {
        let formattedString = "";

        switch (datalayerString) {
            case "":
            case "{}":
            case "[]":
            case "N/A":
                formattedString = "The object is missing";
                break;
            default:
                formattedString = JSON.stringify(JSON.parse(datalayerString), null, 2);
        }

        return formattedString;
    }


    const handleSnapshotAreaUpdate = (row) => {
        if (snapshotToReview === row) setSnapshotExpanded(!snapshotExpanded);
        else setSnapshotExpanded(true);
        setSnapshotToReview(row);
    }


    const handleEventNameField = (e) => setEventNameFilter(e.target.value);


    const CaretComponent = ({ row }) => {
        const handleClick = (event) => {
            event.stopPropagation();
            handleSnapshotAreaUpdate(row);
        };

        return <CaretDownFill size={14} onClick={handleClick} />;
    };

    const UrlComponent = ({ row }) => row?.url;

    function extractHostname(url) {
        // The regular expression pattern to match the hostname
        const regex = /^(?:https?:\/\/)?(?:[^@\/\/]+@)?(?:www\.)?([^:\/\/]+)/;

        // Use the match method to find the hostname
        const match = url?.match(regex);

        // If a match is found, return the hostname; otherwise, return null
        return match ? match[1] : null;

    }

    const errorsListColumns = [
        {
            name: 'Hostname',
            sortable: true,
            width: '15%',
            selector: row => extractHostname(row?.url),
        },
        {
            name: 'Event Name',
            sortable: true,
            width: '17%',
            selector: row => row?.event_name,
        },
        {
            name: 'Error',
            sortable: true,
            width: '23%',
            selector: row => row?.description || row.error_description
        },
        {
            name: 'Last time occured',
            sortable: true,
            width: '16%',
            selector: row => row?.timestamp || "-"
        },
        {
            name: 'Parameter',
            sortable: true,
            width: '15%',
            selector: row => row?.parameter_name || "-"
        },
        {
            name: '',
            sortable: false,
            compact: true,
            width: '1%',
            cell: () => <CaretDownFill size={14} />,
        },
    ];

    const snapshotsListColumns = [
        {
            name: 'Hostname',
            sortable: true,
            width: '50%',
            format: (row) => <div style={{ textWrap: "balance" }}>{row?.url}</div>,
            selector: row => row?.url,
            cell: row => <UrlComponent row={row} />,
        },
        {
            name: 'Event Name',
            sortable: true,
            width: '35%',
            selector: row => row?.event_name,
        },
        {
            name: '',
            sortable: false,
            compact: true,
            width: '5%',
            format: () => <CaretDownFill size={14} />,
            selector: (row, index) => index,
            cell: row => <CaretComponent row={row} />,
        },
    ];


    const lastHourTimestamp = () => {
        const currentDate = new Date();
        currentDate.setHours(currentDate.getHours() - 1);
        return currentDate.getTime();
    }

    const curDate = new Date();
    const weekAgoDate = new Date();
    weekAgoDate.setDate(weekAgoDate.getDate() - 7);
    const minimumDate = new Date();
    minimumDate.setMonth(minimumDate.getMonth() - 3);
    const [dateRange, setDateRange] = useState([weekAgoDate, curDate]);
    const [startDate, setStartDate] = useState(dateRange[0]);
    const [endDate, setEndDate] = useState(dateRange[1]);
    const [updateLoadFlag, setUpdateLoadFlag] = useState(false);

    // Datepicker data
    const [activeTimefilter, setActiveTimefilter] = useState('one-week');

    const currentHour = curDate.getTime();
    const lastHourTimestampValue = lastHourTimestamp();

    const yesterdayDate = new Date();
    yesterdayDate.setDate(yesterdayDate.getDate() - 1);

    const twoWeeksAgoDate = new Date();
    twoWeeksAgoDate.setDate(twoWeeksAgoDate.getDate() - 14);

    // ////////////////////////////////////////////////////////////

    //time filters
    const setOneHourRange = () => {
        setDateRange([lastHourTimestampValue, currentHour]);
        setActiveTimefilter('one-hour');
    }

    const setOneDayRange = () => {
        setDateRange([yesterdayDate, curDate]);
        setActiveTimefilter('one-day');
    }

    const setWeekRange = () => {
        setDateRange([weekAgoDate, curDate]);
        setActiveTimefilter('one-week');

    }

    const setTwoWeeksRange = () => {
        setDateRange([twoWeeksAgoDate, curDate]);
        setActiveTimefilter('two-weeks');

    }
    const [differenceOneDayOrOneHour, setDifferenceOneDayOrOneHour] = useState(null);

    useEffect(() => {
        const [date1, date2] = dateRange;

        setStartDate(date1);
        setEndDate(date2);

        if (date1 !== startDate || date2 !== endDate) {
            const oneDaySpan = getDateRangeCode(date1, date2);
            setDifferenceOneDayOrOneHour(oneDaySpan);

            if (date1 && date2 && companyID === companyId) {
                setUpdateLoadFlag(!updateLoadFlag);
            }

            if (date1 && date2) {
                const formatDate = (date) => format(date, "yyyy-MM-dd");
                const isOneDay = formatDate(date1) === formatDate(yesterdayDate) && formatDate(date2) === formatDate(curDate);
                const isOneWeek = formatDate(date1) === formatDate(weekAgoDate) && formatDate(date2) === formatDate(curDate);
                const isTwoWeeks = formatDate(date1) === formatDate(twoWeeksAgoDate) && formatDate(date2) === formatDate(curDate);

                switch (oneDaySpan) {
                    case "last_24_hours":
                        setActiveTimefilter('one-day');
                        break;
                    case "last_hour":
                        setActiveTimefilter("one-hour");
                        break;
                    case "one_day":
                        if (isOneDay) {
                            setActiveTimefilter('one-day');
                        } else {
                            setActiveTimefilter(null);
                        }
                        break;
                    default:

                        if (isOneWeek) {
                            setActiveTimefilter('one-week');
                        } else if (isTwoWeeks) {
                            setActiveTimefilter('two-weeks');
                        } else {
                            setActiveTimefilter(null);
                        }
                }
            }
        }

    }, [dateRange]);



    useEffect(() => {
        // toggle the apply button based on the selected filters
        // If any filter is added enable the buttons 

        const filterButtonsDisabled = !(choosenDomain || eventNameFilter);
        setfilterButtons(filterButtonsDisabled);

    }, [choosenDomain, eventNameFilter]);


    useEffect(() => {
        (async () => {
            setLoadingActive(true);
            setErrorExpanded(false);
            setSnapshotExpanded(false);

            // Send a request to check if the session is active
            const session = await DashboardService.checkSession();
            const sessionData = await session?.json();
            const sessionExists = sessionData?.session_exists;
            let requestData;

            if (sessionExists) {
                if (startDate && endDate) {

                    if (companyID !== companyId) {
                        setInititalLoad(true);
                        setChoosenDomain("");
                        setDateRange([weekAgoDate, curDate]);
                        setErrorsList([]);
                        setSnapshotsList([]);
                        setDomainsList([]);
                        setStartDate(weekAgoDate);
                        setEndDate(curDate);
                        setEventNameFilter("");
                        setActiveTimefilter("one-week");
                        requestData = {
                            domain: "",
                            start_date: format(weekAgoDate, "yyyy-MM-dd"),
                            end_date: format(curDate, "yyyy-MM-dd"),
                            event_name: "",
                            differenceOneDayOrOneHour: null
                        }

                    } else {
                        requestData = {
                            domain: choosenDomain,
                            start_date: format(dateRange[0], "yyyy-MM-dd"),
                            end_date: format(dateRange[1], "yyyy-MM-dd"),
                            event_name: eventNameFilter,
                            differenceOneDayOrOneHour: differenceOneDayOrOneHour
                        }
                    }

                    const response = await DashboardService.getDLGDashboardData(requestData);
                    const respData = await response.json();
                    const datasetExists = respData?.dataset_exists;

                    if (datasetExists) {

                        setDatasetFound(true);
                        setDomainsList(respData?.context?.company_domains?.map(elem => {
                            return {
                                domain: elem?.fields?.domain,
                                url: elem?.fields?.url,
                            }
                        }));
                        setErrorsList(() => respData?.context?.errors);
                        setSnapshotsList(() => respData?.context?.templates);


                        if (inititalLoad || companyID !== companyId) {
                            setInitialErrorsList(() => respData?.context?.errors);
                            setInitialSnapshotsList(() => respData?.context?.templates);
                        }

                        setInititalLoad(false);

                    } else {
                        setDatasetFound(false);
                    }


                    setCompanyID(companyId);
                } else {
                    alert("Please select a valid start and end date!")
                }

                setLoadingActive(false);
            } else {
                localStorageService.clearLocalStorage();
                navigate('/login');
            }
        })();
    }, [companyId, updateLoadFlag]);

    const handleUpdateClick = () => setUpdateLoadFlag(!updateLoadFlag);

    const handleCopyClick = (value) => {
        const textarea = document.createElement('textarea');
        textarea.value = JSON.stringify(value || {});
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
    };

    return (
        <Content>
            <LoadingScreen isActive={loadingActive} />
            {datasetFound ? (
                <Space direction="vertical" className="container-content" size="large">
                    <Flex justify="space-between" align="flex-start">
                        <PageHeader categoryName="dashboard" pageName="DataLayer Guard" />
                        <Space>
                            <div className="row justify-content-end pb-2">
                                <div className="col text-end time-filters">
                                    <span
                                        className={`time-span ${activeTimefilter === 'one-hour' ? 'text-black' : ''}`}
                                        onClick={setOneHourRange}>
                                        LAST HOUR
                                    </span>
                                    <span
                                        className={`time-span ${activeTimefilter === 'one-day' ? 'text-black' : ''}`}
                                        onClick={setOneDayRange}>
                                        24 HOURS
                                    </span>
                                    <span
                                        className={`time-span ${activeTimefilter === 'one-week' ? 'text-black' : ''}`}
                                        onClick={setWeekRange}>
                                        7 DAYS
                                    </span>
                                    <span
                                        className={`time-span ${activeTimefilter === 'two-weeks' ? 'text-black' : ''}`}
                                        onClick={setTwoWeeksRange}>
                                        14 DAYS
                                    </span>


                                    <div className="litepickerDiv dropdown-filter">
                                        <Calendar3 className="calendar-icon" size={18} />
                                        <DatePicker
                                            selectsRange={true}
                                            startDate={startDate}
                                            endDate={endDate}
                                            showPreviousMonths={true}
                                            monthsShown={2}
                                            onChange={(update) => {
                                                setDateRange(update);
                                                setActiveTimefilter("");
                                            }}
                                            dateFormat="MM-dd-yyyy"
                                            popperPlacement="bottom-start"
                                            minDate={minimumDate}
                                            maxDate={new Date()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Space>
                    </Flex>

                    <div>
                        <Space direction="vertical" size="large">

                            <Tabs
                                items={[
                                    {
                                        name: "errors",
                                        title: "encountered errors",
                                        onClickFunction: () => handleTabsVisibilityChange("errors"),
                                    },
                                    {
                                        name: "overview",
                                        title: "dataLayer overview",
                                        onClickFunction: () => handleTabsVisibilityChange("overview"),
                                    },
                                ]}
                                activeTab={activeTab}
                            />

                            <div>
                                {tabsVisibility["errors"] ? (
                                    <h5>Encountered errors in your dataLayer</h5>
                                ) : tabsVisibility["overview"] ? (
                                    <h5>Overview of the latest DataLayer snapshots</h5>
                                ) : ""}
                            </div>


                            {/* filters */}
                            <Flex gap={10}>
                                <div className="dropdown dropdown-filter">
                                    <button
                                        className="btn btn-secondary dropdown-toggle dropbtn rounded-dropdown"
                                        type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {choosenDomain ? choosenDomain : "All Domains"}
                                    </button>
                                    <ul className="dropdown-menu" style={{ minWidth: '13rem' }}>
                                        <li className="pointer-on-hover" onClick={() => setChoosenDomain("")}>
                                            <div className="dropdown-item">All domains</div>
                                        </li>
                                        {domainsList?.map((elem, idx) =>
                                            <li className="pointer-on-hover" key={elem.domain + idx} onClick={() => setChoosenDomain(elem.domain)}>
                                                <div className="dropdown-item">{elem.domain}</div>
                                            </li>
                                        )}

                                    </ul>
                                </div>


                                <div className="align-self-stretch dropdown-filter">
                                    <input
                                        style={{ padding: '.21em .8em', borderRadius: 4 }}
                                        type="text"
                                        placeholder="Event name"
                                        value={eventNameFilter || ""}
                                        onInput={(e) => handleEventNameField(e)}
                                    />
                                </div>

                                <div className='dropdown-filter'>
                                    <button
                                        className="btn btn-sm cc-btn-purple"
                                        onClick={handleUpdateClick}
                                        disabled={filterButtons}
                                    >
                                        Apply
                                    </button>

                                </div>

                                <div className='dropdown-filter'>
                                    <button type="button" className="btn btn-sm cc-btn-peach"
                                        onClick={() => { refreshFilters() }}
                                    >
                                        Reset
                                    </button>
                                </div>
                            </Flex>
                        </Space>

                        <Col>
                            <div className={`charts-area ${tabsVisibility["errors"] ? "visible" : ""}`}>
                                <Table
                                    columns={errorsListColumns}
                                    tableData={errorsList}
                                    rowClickFunction={handleErrorAreaUpdate}
                                    filterParam="event_name"
                                />

                                <div className={`mb-3 ${errorExpanded ? "slide-down" : "slide-up"}`}>
                                    <div className="row" style={{ display: "flex" }}>
                                        <div className="elems-list col-6 equal-height-div">
                                            <h5><strong>Errors in detail</strong></h5>
                                            <div className="error-details-desc">
                                                <div><strong>Event:</strong> {errorToReview?.event_name}</div>
                                                <div><strong>Type of Error:</strong> {errorToReview?.error_description}</div>
                                                <div><strong>Last time it was encountered:</strong> {errorToReview?.timestamp}</div>
                                                <div><strong>Number of times the error encountered:</strong> {errorToReview?.occurrence_count}</div>
                                                <div id="urls">
                                                    <strong>URL where the error is found:</strong><br />
                                                    {errorToReview?.url}
                                                </div>
                                            </div>

                                            <div className="pt-5">{issueContent}</div>
                                        </div>

                                        <div className="text-area col-6 equal-height-div">
                                            <div className="text-area-block" style={{ marginBottom: 0 }}>
                                                <div className="text-area-title">
                                                    <h5><strong>Snapshot of your Datalayer</strong></h5>
                                                </div>
                                                <div className="text-area-clipboard">
                                                    <div className="text-area-copy-button" onClick={() => handleCopyClick(JSON.parse(errorToReview?.datalayer_string) || {})}>
                                                        <Clipboard size={18} />
                                                    </div>
                                                    <textarea className="datalayer-string-snp" disabled={true}
                                                        value={dataLayerContentPreview(errorToReview?.datalayer_string || "")} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <div className={`charts-area ${tabsVisibility["overview"] ? "visible" : ""}`}>
                            <div>
                                <Table
                                    columns={snapshotsListColumns}
                                    tableData={snapshotsList}
                                    rowClickFunction={handleSnapshotAreaUpdate}
                                    filterParam="event_name"
                                />

                                <div className={`mb- 3 text-area ${snapshotExpanded ? "slide-down" : "slide-up"}`}>
                                    <div className="text-area-block">
                                        <div className="text-area-title">
                                            <strong>URL:</strong> {snapshotToReview?.hostname || ""}
                                        </div>
                                        <div className="text-area-clipboard">
                                            <div className="text-area-copy-button" onClick={() => handleCopyClick(snapshotToReview?.event)}>
                                                <Clipboard size={18} />
                                            </div>
                                            <textarea style={{ minHeight: '25rem' }} className="datalayer-string-snp" disabled={true}
                                                value={JSON.stringify(snapshotToReview?.event || {}, null, 4)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Space>
            ) : (
                <div className="col-12 col-sm-10 col-md-8 text-start w-100">
                    <h1>Dataset not found!</h1>
                </div>
            )}
        </Content>
    );
}
