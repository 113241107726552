import React from 'react';
import companyLogo from '../assets/codecube-horizontal-colour.png';


export const CompanyLogo = () => {
    return(
        <div className='text-center'>
            <img className='my-4' src={companyLogo} alt="Company logo" height={45} />
        </div>
    )
}
