import gaIconColored from '../../assets/ga-logo-colored.svg';

export const defaultEventTemplates = {
    "begin_checkout": {
        "event": "checkout",
        "ecommerce": {
            "currency": "string",
            "value": "string",
            "checkout": {
                "actionField": {
                    "step": "number",
                    "action": "string"
                },
                "items": [
                    {
                        "item_id": "string",
                        "item_name": "string",
                        "affiliation": "string",
                        "discount": "number",
                        "index": "number",
                        "item_brand": "string",
                        "item_category": "string",
                        "item_category2": "string",
                        "item_category3": "string",
                        "item_category4": "string",
                        "item_category5": "string",
                        "item_list_name": "string",
                        "item_variant": "string",
                        "price": "number",
                        "quantity": "number",
                    }
                ]
            }
        }
    },
    "view_item_list": {
        "event": "view_item_list",
        "ecommerce": {
            "items": [
                {
                    "item_id": "string",
                    "item_name": "string",
                    "affiliation": "string",
                    "discount": "number",
                    "index": "number",
                    "item_brand": "string",
                    "item_category": "string",
                    "item_category2": "string",
                    "item_category3": "string",
                    "item_category4": "string",
                    "item_category5": "string",
                    "item_list_name": "string",
                    "item_variant": "string",
                    "price": "number",
                    "quantity": "number",
                }
            ]
        }
    },
    "view_item": {
        "event": "view_item",
        "ecommerce": {
            "currency": "string",
            "value": "number",
            "items": [
                {
                    "item_id": "string",
                    "item_name": "string",
                    "affiliation": "string",
                    "discount": "number",
                    "item_brand": "string",
                    "item_category": "string",
                    "item_category2": "string",
                    "item_category3": "string",
                    "item_category4": "string",
                    "item_category5": "string",
                    "item_list_name": "string",
                    "item_variant": "string",
                    "price": "number",
                    "quantity": "number",
                }
            ]
        }
    },
    "add_to_cart": {
        "event": "add_to_cart",
        "ecommerce": {
            "currency": "string",
            "value": "number",
            "items": [
                {
                    "item_id": "string",
                    "item_name": "string",
                    "affiliation": "string",
                    "discount": "number",
                    "item_brand": "string",
                    "item_category": "string",
                    "item_category2": "string",
                    "item_category3": "string",
                    "item_category4": "string",
                    "item_category5": "string",
                    "item_list_name": "string",
                    "item_variant": "string",
                    "price": "number",
                    "quantity": "number",
                }
            ]
        }
    },
    "remove_from_cart": {
        "event": "remove_from_cart",
        "ecommerce": {
            "currency": "string",
            "value": "number",
            "items": [
                {
                    "item_id": "string",
                    "item_name": "string",
                    "affiliation": "string",
                    "discount": "number",
                    "item_brand": "string",
                    "item_category": "string",
                    "item_category2": "string",
                    "item_category3": "string",
                    "item_category4": "string",
                    "item_category5": "string",
                    "item_variant": "string",
                    "location_id": "string",
                    "price": "number",
                    "quantity": "number",
                }
            ]
        }
    },
    "view_cart": {
        "event": "view_cart",
        "ecommerce": {
            "currency": "string",
            "value": "number",
            "items": [
                {
                    "item_id": "string",
                    "item_name": "string",
                    "affiliation": "string",
                    "discount": "number",
                    "index": "number",
                    "item_brand": "string",
                    "item_category": "string",
                    "item_category2": "string",
                    "item_category3": "string",
                    "item_category4": "string",
                    "item_category5": "string",
                    "item_variant": "string",
                    "price": "number",
                    "quantity": "number",
                }
            ]
        }
    },
};

export const defaultMenuProps = [
    {
        label: "add_to_cart",
        key: "add_to_cart",
        icon: <img className="sidebar-logo-icon" src={gaIconColored} alt="Google Analytics 4" height="16" />,
        styles: "ga4-icon-preload"
    },
    {
        label: "begin_checkout",
        key: "begin_checkout",
        icon: <img className="sidebar-logo-icon" src={gaIconColored} alt="Google Analytics 4" height="16" />,
        styles: "ga4-icon-preload"
    },
    {
        label: "remove_from_cart",
        key: "remove_from_cart",
        icon: <img className="sidebar-logo-icon" src={gaIconColored} alt="Google Analytics 4" height="16" />,
        styles: "ga4-icon-preload"
    },
    {
        label: "view_cart",
        key: "view_cart",
        icon: <img className="sidebar-logo-icon" src={gaIconColored} alt="Google Analytics 4" height="16" />,
        styles: "ga4-icon-preload"
    },
    {
        label: "view_item",
        key: "view_item",
        icon: <img className="sidebar-logo-icon" src={gaIconColored} alt="Google Analytics 4" height="16" />,
        styles: "ga4-icon-preload"
    },
    {
        label: "view_item_list",
        key: "view_item_list",
        icon: <img className="sidebar-logo-icon" src={gaIconColored} alt="Google Analytics 4" height="16" />,
        styles: "ga4-icon-preload"
    },
];
