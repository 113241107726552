import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Col } from 'antd';

import './styles.scss';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const defaultOptions = (indexAxis) => ({
    indexAxis: indexAxis,
    plugins: {
        tooltip: {
            callbacks: {
                label: (tooltipItem) => `${tooltipItem.dataset.label}: ${tooltipItem.raw}%`,
            },
        },
        legend: {
            position: "top",
            align: "start",
            labels: {
                boxWidth: 35,
                boxHeight: 8,
                useBorderRadius: true,
                borderRadius: 2.5,
                font: {
                    family: "Inter"
                },
            },
        },
    },
    responsive: true,
    maintainAspectRatio: true,
    scales: {
        x: {
            stacked: true,
            grid: {
                display: false, // Disable grid lines on the X axis
            },
        },
        y: {
            stacked: true,
            beginAtZero: true,
            min: 0,
            max: 100,
            grid: {
                display: false, // Disable grid lines on the X axis
            },
        },
    },
});

const BarChart = (props) => {
    const {
        data = [],
        transformChartData = (data) => data,
        indexAxis = "y",
        plugins = [],
        chartOptions = defaultOptions(indexAxis),
    } = props;

    const chartData = transformChartData(data);

    return (
        <div className="chart-wrapper">
            <Col span={24}>
                <div className="bar-chart-area">
                    <Bar data={chartData} options={chartOptions} plugins={plugins} />
                </div>
            </Col>
        </div>
    );
}

export default BarChart;
