import React, { useState, useEffect, useRef, useContext } from 'react';
import { Building } from 'react-bootstrap-icons';

import { AuthContext } from '../../../services/auth.service';

import './styles.scss';

const Dropdown = (props) => {
    const { list, hovered, hoverOutSidebar } = props;
    const { companyId, handleCompanyChange } = useContext(AuthContext);
    const dropdownRef = useRef();

    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [choosenCompanyId, setChoosenCompanyId] = useState(companyId);
    const [choosenCompanyName, setChoosenCompanyName] = useState("");

    const sortedList = Array.isArray(list) ? list?.sort((a, b) => {
        const nameA = a?.name.toLowerCase();
        const nameB = b?.name.toLowerCase();

        if (nameA < nameB) return -1;
        else if (nameA > nameB) return 1;

        return 0;
    } ) : [];

    useEffect(() => {
        dropdownRef.current.blur();
        setDropdownOpen(hovered);
    }, [hovered]);

    useEffect(() => {
        const company = list?.find(elem => elem.id === choosenCompanyId);
        setChoosenCompanyName(company?.name);
    }, [choosenCompanyId]);

    const handleDropdownValueChange = (e) => {
        const company = list?.find(elem => elem.name === e.target.value);
        setChoosenCompanyId(company?.id);
        handleCompanyChange(company?.id);
        setChoosenCompanyName(company?.name);

        hoverOutSidebar();
    }

    return (
        <div className="company-list-block">
            <Building color="black" size={16} />
            <select className={`company-list ${isDropdownOpen ? "expanded" : ""}`}
                ref={dropdownRef}
                onChange={(e) => handleDropdownValueChange(e)}
                value={choosenCompanyName}
            >
                { sortedList.map(elem => 
                    <option key={elem.id}>{elem?.name}</option>
                )}
            </select>
        </div>
    );
};

export default Dropdown;
