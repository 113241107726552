import React, { useState, useEffect } from 'react';
import loadingIcon from '../../../assets/loading.svg';

import './styles.scss';

const LoadingScreen = (props) => {
    const { isActive = false } = props;

    const [loadingScreenClass, setLoadingScreenClass] = useState("visible");

    useEffect(() => {
        setLoadingScreenClass(isActive ? "visible" : "");
    }, [isActive]);

    return (
        <div className={`loading-screen ${loadingScreenClass}`}>
            <img className="loading-icon" src={loadingIcon} height="60" />
        </div>
    );
};

export default LoadingScreen;
