import React, { useState } from 'react';
import { Modal } from 'antd';

export const ConfirmModal = ({ 
    visible, 
    onConfirm, 
    onCancel, 
    title = "Are you sure?", 
    content = "Do you want to proceed with this action?", 
    okText = "Confirm", 
    cancelText = "Cancel" 
}) => {

    return (
        <Modal
            open={visible}
            title={title}
            onOk={onConfirm}
            onCancel={onCancel}
            okText={okText}
            cancelText={cancelText}
        >
            <p>{content}</p>
        </Modal>
    );
};