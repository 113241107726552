import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Person, BoxArrowRight } from 'react-bootstrap-icons';

import localStorageService from '../../../../../services/localStorage.service';

import "./styles.scss";

const MenuCategory = (props) => {
    const {
        hovered,
    } = props;

    const [username, setUsername] = useState("");

    useEffect(() => {
        const userData = localStorageService.getUserData();
        setUsername(userData?.full_name);
    }, []);

    return (
        <div className="sidebar-profile-container">
            <div className="sidebar-profile-area title-sliding">
                <div className="sidebar-profile-name">
                    <div>
                        <div>Welcome,</div>
                        <div>{username}!</div>
                    </div>
                    <Link to="/logout" className="sidebar-profile-logout" title='Logout'>
                        <BoxArrowRight size={20} />
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default MenuCategory;