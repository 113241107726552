import { React } from 'react';

import "./styles.scss";

export const InteractivePanel = (props) => {
    const {
        icon,
        headerText,
        descriptionText,
        available,
        clickFunction,
    } = props;

    return(
        <div className={`panel-button-container ${!available ? "disabled" : ""}`} onClick={clickFunction}>
            <div className="panel-button-icon">{icon}</div>
            <div className="panel-button-text">
                <div className="panel-button-header">{headerText}</div>
                <div className="panel-button-desc">{descriptionText}</div>
            </div>
        </div>
    );
};
