import { React, useState, useEffect, useRef } from 'react';
import { DashCircle } from 'react-bootstrap-icons';
import { Select, Button } from 'antd';
import { UploadOutlined, WarningFilled } from '@ant-design/icons';
import { InfoCircleDiv } from '../../components/elements/InfoHoverableDiv';
import {
    Form,
    Input,
    Upload,
    Switch,
    Space,
} from 'antd';


const regionLists = [
    "asia-east1 (Taiwan)",
    "asia-northeast1 (Tokyo)",
    "asia-northeast2 (Osaka)",
    "europe-north1 (Finland)",
    "europe-southwest1 (Madrid)",
    "europe-west1 (Belgium)",
    "europe-west4 (Netherlands)",
    "europe-west8 (Milan)",
    "europe-west9 (Paris)",
    "me-west1 (Tel Aviv)",
    "us-central1 (Iowa)",
    "us-east1 (South Carolina)",
    "us-east4 (Northern Virginia)",
    "us-east5 (Columbus)",
    "us-south1 (Dallas)",
    "us-west1 (Oregon)",
    "africa-south1 (Johannesburg)",
    "asia-east2 (Hong Kong)",
    "asia-northeast3 (Seoul, South Korea)",
    "asia-southeast1 (Singapore)",
    "asia-southeast2 (Jakarta)",
    "asia-south1 (Mumbai, India)",
    "asia-south2 (Delhi, India)",
    "australia-southeast1 (Sydney)",
    "australia-southeast2 (Melbourne)",
    "europe-central2 (Warsaw, Poland)",
    "europe-west10 (Berlin)",
    "europe-west12 (Turin)",
    "europe-west2 (London, UK)",
    "europe-west3 (Frankfurt, Germany)",
    "europe-west6 (Zurich, Switzerland)",
    "me-central1 (Doha)",
    "me-central2 (Dammam)",
    "northamerica-northeast1 (Montreal)",
    "northamerica-northeast2 (Toronto)",
    "southamerica-east1 (Sao Paulo, Brazil)",
    "southamerica-west1 (Santiago, Chile)",
    "us-west2 (Los Angeles)",
    "us-west3 (Salt Lake City)",
    "us-west4 (Las Vegas)"
];

const regionItems = regionLists.map((region) => {
    const key = region.split(" (")[0];
    return key;
});

const infoText = `
    It looks like this feature is part of our premium offerings. 
    If you’re interested in accessing it, please reach out to us, 
    and we’ll be happy to assist you with upgrading your plan.
`;


const GoogleCloudConfigForm = (props) => {
    const {
        tagMonitorSubscription,
        saveFunction,
        cloudRunData,
        setModalSaveStatus,
    } = props;

    const [form] = Form.useForm();
    const [configData, setConfigData] = useState(cloudRunData?.config_data);

    const [enableCloudRun, setEnableCloudRun] = useState(configData?.current_config?.cloud_run_monitoring || false);
    const [serverErrorState, setServerErrorState] = useState(configData?.current_config?.ss_error_monitoring || false);
    const [inputValue, setInputValue] = useState(configData?.current_config?.project_number);
    const [resourcesList, setResourcesList] = useState([""]);

    const initCloudRunState = useRef([]);
    const initServerErrorState = useRef([]);
    const initialResourcesListRef = useRef([]);

    const serverErrorLoggingDisabled = tagMonitorSubscription === "essentials" || tagMonitorSubscription === "advanced";

    useEffect(() => setConfigData(cloudRunData?.config_data), [cloudRunData]);

    useEffect(() => {
        if (configData) {
            const initialResources = JSON.parse(configData?.current_config?.cloud_run_resources_list?.replace(/'/g, '"') || "[]") || [""];
            setResourcesList(initialResources);

            if (!Object.keys(initCloudRunState).find(elem => elem === "current") || initCloudRunState.current.length === 0)
                initCloudRunState.current = enableCloudRun;

            if (!Object.keys(initServerErrorState).find(elem => elem === "current") || initServerErrorState.current.length === 0)
                initServerErrorState.current = serverErrorState;

            if (initialResourcesListRef.current.length === 0) initialResourcesListRef.current = initialResources;

            form.setFieldsValue({
                project_number: configData?.current_config?.project_number,
                items: initialResources,
                ss_error_monitoring: configData?.current_config?.ss_error_monitoring,
                cloud_run_monitoring: configData?.current_config?.cloud_run_monitoring,
                upload: []
            });
        }

        setEnableCloudRun(configData?.current_config?.cloud_run_monitoring || false);
        setServerErrorState(configData?.current_config?.ss_error_monitoring || false);

        setModalSaveStatus(
            enableCloudRun !== initCloudRunState.current
            || serverErrorState !== initServerErrorState.current
            || inputValue !== configData?.current_config?.project_number
        );
    }, [configData]);

    useEffect(() => {
        const resourcesListChanged = JSON.stringify(resourcesList) !== JSON.stringify(initialResourcesListRef.current);

        setModalSaveStatus(
            enableCloudRun !== initCloudRunState.current ||
            serverErrorState !== initServerErrorState.current ||
            inputValue !== configData?.current_config?.project_number ||
            resourcesListChanged
        );
    }, [enableCloudRun, serverErrorState, inputValue, resourcesList]);

    const onFinish = (values) => {
        if (values["items"]) {
            let trimmedData = values["items"]?.map(item => {
                return {
                    name: item.name.trim(),
                    region: item.region.trim()
                };
            });
            values["items"] = trimmedData;
        }
        saveFunction(values);
    };


    return (
        <div>
            <Form
                name="GoogleCloudConfigForm"
                onFinish={onFinish}
                layout='vertical'
                form={form}
                initialValues={{
                    items: resourcesList,
                    ss_error_monitoring: configData?.current_config?.ss_error_monitoring,
                    cloud_run_monitoring: configData?.current_config?.cloud_run_monitoring,
                    upload: [],
                    remember: true
                }}
            >
                <div className='mb-3'>
                    <div className="main-headings">
                        Cloud Run monitoring
                    </div>
                    <p>
                        To enable Cloud Run monitoring some additional information on your tagging instances is required.
                    </p>
                    <Form.Item
                        name="cloud_run_monitoring"
                        valuePropName="checked"
                        style={{ display: "inline-block" }}
                    >
                        <Switch
                            disabled={configData?.current_config?.cloud_run_monitoring}
                            onChange={(value) => setEnableCloudRun(value)}
                        />
                    </Form.Item>
                    <p className='ps-2' style={{ lineHeight: "34px", display: "inline-block" }}>
                        {enableCloudRun ? "Disable Cloud Run monitoring" : "Enable Cloud Run monitoring"}
                    </p>
                </div>

                <div>
                    <div className="main-headings d-flex" style={{ position: "relative" }}>
                        Server-side error logs
                        {serverErrorLoggingDisabled &&
                            <InfoCircleDiv content={infoText} top="-8.5em" />
                        }
                    </div>
                    <Form.Item
                        valuePropName="checked"
                        name="ss_error_monitoring"
                        style={{ display: "inline-block" }}
                    >
                        <Switch
                            onChange={(value) => setServerErrorState(value)}
                            disabled={configData?.current_config?.ss_error_monitoring || serverErrorLoggingDisabled}
                        />
                    </Form.Item>
                    <p className='ps-2' style={{ lineHeight: "34px", display: "inline-block" }}>
                        {serverErrorState ? "Disable server-side error logs" : "Enable server-side error logs"}
                    </p>
                </div>

                {enableCloudRun && (
                    <div>
                        <div className='pt-3'>
                            <div className="main-headings">Google Cloud Platform details</div>
                            <p>
                                Please feel in the details on your Google Cloud Platform project,
                                where the GTM tagging server is hosted.
                            </p>
                        </div>

                        <Form.Item
                            label="Project number"
                            name="project_number"
                            rules={[{ required: true, message: 'Please add your project number!' }]}
                        >
                            <Input
                                style={{ width: '17rem' }}
                                placeholder='485687865'
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                            />
                        </Form.Item>
                    </div>
                )}
                {(enableCloudRun || serverErrorState) && (
                    <div>
                        <div className="main-headings">
                            Service account key
                        </div>
                        <p>
                            Please create a service account in your Google Cloud Platform and upload the JSON file below.
                            More information on how the service account can be created and which permissions are required can
                            found <a
                                style={{ color: "#000" }}
                                target="blank"
                                href="https://docs.code-cube.io/tag-monitor/cloud-run-monitoring/#create-service-account-key">
                                here
                            </a>
                            .
                        </p>
                        {/* Upload Field */}
                        <Form.Item
                            name="upload"
                            valuePropName="fileList"
                            getValueFromEvent={(e) => {
                                if (Array.isArray(e)) {
                                    return e;
                                }
                                return e && e.fileList;
                            }}
                            rules={[{ required: true, message: 'Please upload a file!' }]}
                        >
                            <Upload
                                accept='.json'
                                name="logo"
                                action="/upload.do"
                                listType="text"
                                beforeUpload={() => false}
                            >
                                <div className="btn btn-sm cc-btn-outline-blue">
                                    <UploadOutlined style={{ paddingRight: ".8rem" }} />
                                    Upload key
                                </div>
                                <WarningFilled style={{ color: "rgb(246 214 0)", paddingLeft: "1rem", fontSize: "1rem" }} />
                                <span className='ps-1' style={{ fontStyle: "italic", fontSize: ".8rem" }}>
                                    The file will never be stored in any of our systems.
                                </span>

                            </Upload>
                        </Form.Item>
                    </div>
                )}
                {enableCloudRun && (
                    <div className='mt-5'>
                        <div className="main-headings">
                            Cloud Run Instances
                        </div>
                        <p>
                            Please enter the name and region of your Cloud
                            Run tagging services below.
                            Learn how to locate the Cloud Run resources <a
                                style={{ color: "#000" }}
                                target="blank"
                                href="https://docs.code-cube.io/tag-monitor/cloud-run-monitoring/#locate-cloud-run-resources">
                                here
                            </a>
                            .
                        </p>

                        <DynamicInputField setResourcesList={setResourcesList} resourcesList={resourcesList} />
                        <div className='mb-5'></div>
                    </div>
                )}
                {(enableCloudRun || serverErrorState) && (
                    <div className='mt-5'>
                        {/* Submit Button */}
                        <Form.Item>
                            <Button type="primary" id="orange-btn" htmlType="submit">
                                Start configuration
                            </Button>
                        </Form.Item>
                    </div>
                )}
            </Form>
        </div>
    );
};


const DynamicInputField = (props) => {
    const { resourcesList, setResourcesList } = props;

    const handleAdd = (add, fields) => {
        add();
        const newList = [...resourcesList, { name: "", region: "" }];
        setResourcesList(newList);
    };

    const handleRemove = (name, remove, fields) => {
        remove(name);
        const newList = resourcesList.filter((_, index) => index !== name);
        setResourcesList(newList);
    };

    const handleFieldChange = (index, field, value, fields) => {
        const updatedList = [...resourcesList];
        updatedList[index] = {
            ...updatedList[index],
            [field]: value
        };
        setResourcesList(updatedList);
    };

    return (
        <Form.List
            name="items"
            rules={[
                {
                    validator: async (_, names) => {
                        if (!names || names.length < 1) {
                            return Promise.reject(new Error('At least 1 resource name is required'));
                        }
                    },
                },
            ]}
        >
            {(fields, { add, remove }, { errors }) => (
                <>
                    {fields.map(({ key, name, ...field }, index) => (
                        <div key={`${field.key}-${index}`} style={{ display: 'flex', alignItems: 'center' }}>
                            <Space key={key} width={"100%"}>
                                <Form.Item
                                    {...field}
                                    name={[name, 'name']}
                                    fieldKey={[key, 'name']}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: "Please input a resource name",
                                        },
                                    ]}
                                    style={{ marginRight: 8, minWidth: "17rem" }}
                                >
                                    <Input
                                        placeholder="Resource name"
                                        onChange={(e) => handleFieldChange(name, 'name', e.target.value, fields)}
                                    />
                                </Form.Item>

                                <Form.Item
                                    {...field}
                                    name={[name, 'region']}
                                    fieldKey={[key, 'region']}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: "Please select a region",
                                        },
                                    ]}
                                    style={{ marginRight: 8 }}
                                >
                                    <Select
                                        placeholder="Select the resource region"
                                        style={{ minWidth: "14rem" }}
                                        showSearch
                                        onChange={(value) => handleFieldChange(name, 'region', value, fields)}
                                    >
                                        {regionItems.map((item, index) => (
                                            <Select.Option key={`${item}-${index}`} value={item}>
                                                {item}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                {fields.length > 1 ? (
                                    <Form.Item>
                                        <DashCircle
                                            className='color-blue ms-3'
                                            size={18}
                                            onClick={() => handleRemove(name, remove, fields)}
                                        />
                                    </Form.Item>
                                ) : null}
                            </Space>
                        </div>
                    ))}

                    <Form.Item>
                        <div className="btn btn-sm cc-btn-outline-blue" onClick={() => handleAdd(add, fields)}>+ add instance</div>
                        <Form.ErrorList errors={errors} />
                    </Form.Item>
                </>
            )}
        </Form.List>
    );
};

export default GoogleCloudConfigForm;
