import React, { useEffect, useState, useContext } from 'react';
import { BarChart, Gear } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import DashboardService from '../../services/dashboard.service';
import "./styles.scss";
import { useNavigate } from 'react-router-dom';
import localStorageService from '../../services/localStorage.service';
import { AuthContext } from '../../services/auth.service';

/**
 * Home page component
 * 
 * @returns {ReactNode} Component with main page content
 */
const Home = () => {
    const { companyInfo } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            // Send a request to check if the session is active
            const session = await DashboardService.checkSession();
            const sessionData = await session?.json();
            const sessionExists = sessionData?.session_exists;

            if (!sessionExists) {
                localStorageService.clearLocalStorage();
                navigate('/login');
            }
        })();
    });

    const [dlgStatus, setDlgStatus] = useState("");
    const [ssmStatus, setSsmStatus] = useState("");
    const [tmStatus, setTmStatus] = useState("");

    useEffect(() => {
        setDlgStatus(companyInfo?.datalayer_guard ? "" : "inactive");
        setSsmStatus(companyInfo?.sitespeed_monitor ? "" : "inactive");
        setTmStatus(companyInfo?.tag_monitor ? "" : "inactive");
    }, [companyInfo])

    return (
        <div className="container row">
            <div className="col-12 text-start">
                <h2>Welcome to Code Cube Portal!</h2>
                <br />
                <section className="panels-area">
                    <div className="panels-rows mb-5">
                        <p className="p-main-text">
                            Here you can find your real-time insights via the dashboards, configure the Code Cube products or update your personal information.<br /><br />
                            Any questions? Please <span style={{ 'textDecoration': 'underline' }}>contact us</span>.
                        </p>
                    </div>
                </section>
                <section className="panels-area">
                    <div className="panels-rows">
                        <h3 className="sub-headings flex-container"><BarChart size={22} />Dashboards</h3>
                    </div>
                    <div className="panels-cols">
                        <div className="row mb-5 bg-light-panel panel-col-flex">
                            <div className="panels-cols-content">
                                <div className="align-center">
                                    <h3 className="text-center m-0 mb-3">Tag Monitor</h3>
                                </div>
                                <div className="align-baseline mx-auto">
                                    <Link to="/tag_monitor_dashboard"
                                        className={`btn orange-btn-sm btn-sm py-1 px-3 ${tmStatus}`}>
                                        DASHBOARD
                                        <i className="bi bi-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-5 bg-light-panel panel-col-flex">
                            <div className="panels-cols-content">
                                <div className="align-center">
                                    <h3 className="text-center m-0 mb-3">DataLayer Guard</h3>
                                </div>
                                <div className="align-baseline mx-auto">
                                    <Link to="/datalayer_guard_dashboard"
                                        className={`btn orange-btn-sm btn-sm py-1 px-3 ${dlgStatus}`}>
                                        DASHBOARD
                                        <i className="bi bi-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="panels-area">
                    <div className="panels-rows">
                        <h3 className="sub-headings flex-container"><Gear size={22} />Configuration</h3>
                    </div>
                    <div className="panels-cols">
                        <div className="row mb-5 bg-light-panel panel-col-flex">
                            <div className="panels-cols-content">
                                <div className="align-center">
                                    <h3 className="text-center m-0 mb-3">Tag Monitor</h3>
                                </div>
                                <div className="align-baseline mx-auto">
                                    <Link to="/tag_monitor_config"
                                        className={`btn purpule-btn-sm btn-sm py-1 px-3 ${tmStatus}`}>
                                        CONFIGURE
                                        <i className="bi bi-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-5 bg-light-panel panel-col-flex">
                            <div className="panels-cols-content">
                                <div className="align-center">
                                    <h3 className="text-center m-0 mb-3">DataLayer Guard</h3>
                                </div>
                                <div className="align-baseline mx-auto">
                                    <Link to="/datalayer_guard_config"
                                        className={`btn purpule-btn-sm btn-sm py-1 px-3 ${dlgStatus}`}>
                                        CONFIGURE
                                        <i className="bi bi-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-5 bg-light-panel panel-col-flex">
                            <div className="panels-cols-content">
                                <div className="align-center">
                                    <h3 className="text-center m-0 mb-3">Sitespeed Monitor</h3>
                                </div>
                                <div className="align-baseline mx-auto">
                                    <Link to="/sitespeed_monitor_config"
                                        className={`btn purpule-btn-sm btn-sm py-1 px-3 ${ssmStatus}`}>
                                        CONFIGURE
                                        <i className="bi bi-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Home;
