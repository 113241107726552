import React, { useState, useEffect } from 'react';
import { Dropdown, Button, Space } from "antd";

export const DropdownMenu = (props) => {
    const {
        text = "Save",
        type = "primary",
        disabled = false,
        loading = false,
        menuProps = {},
        icon = null,
        iconPosition = "end",
        width
    } = props;

    const [localLoading, setLocalLoading] = useState(loading);
    useEffect(() => setLocalLoading(loading), [loading]);

    return (
        <Dropdown menu={menuProps}
            disabled={disabled}>
            <Button
                type={type}
                loading={localLoading}
                icon={icon}
                iconPosition={iconPosition}
                style={width ? { width: width } : ""}
            >
                <Space>
                    {text}
                </Space>
            </Button>
        </Dropdown>
    );
};
