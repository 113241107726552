import React, { useEffect, useState, useRef  } from 'react';
import localStorageService from '../../services/localStorage.service';
import GTMServiceAPI from '../../services/gtm.API.service';
import LoadingScreen from '../../components/elements/LoadingScreen';
import { Select, ConfigProvider } from 'antd';
import { Steps, Alert, Space } from 'antd';
import FullLoadingScreen from './FullLoadingScreen';


const { Step } = Steps;

export const AuthorizedGTM = (props) => {

    const [containers , setContainers] = useState([]);
    const [accounts , setAccounts] = useState([]);
    const [loadingActive, setLoadingActive] = useState(false);
    const [loadingWithTextActive, setLoadingWithTextActive] = useState(false);
    const [selectedAccount , setSelectedAccount] = useState("");
    const [selectedContainer , setSelectedContainer] = useState({});
    const [errorMsg , setErrorMsg] = useState("");
    const [ showMessage, setShowMessage ] = useState(false);
    const [ tagsUpdated, setTagsUpdated ] = useState(false);
    const [ current, setCurrent ] = useState(0);

    const authorizedGTM = props.authorizedGTM;
    const authUrl = props.authUrl;
    const UserEmail = localStorageService.getUserData()?.email;


    const successMessage = `
        Tags updated successfully.
        Please navigate to your container and review the changes in the newly 
        generated Code Cube workspace. Then, publish the container for the changes
        to take effect.
    `;

                    
    const HandleSelectContainer = async (containerId) =>{
        const selectedFilterName = containers.find((id) => id.containerId === containerId);
        setSelectedContainer(selectedFilterName);

    }


    const handleRunUpdate = async () =>{
        // Send a request to check if the session is active
        // setLoadingActive(true);
        setLoadingWithTextActive(true);
        const response = await GTMServiceAPI.GetTags(selectedContainer.containerId, selectedAccount, UserEmail);
        setErrorMsg(response?.response_object?.error_msg);
        // setLoadingActive(false);
        setTagsUpdated(response?.response_object?.updated_tags);
        setShowMessage(true);
        setLoadingWithTextActive(false);
    }


    const HandleSelectAccount = async (accountId) =>{
        // Send a request to check if the session is active
        setLoadingActive(true);
        const response = await GTMServiceAPI.GetContainers(accountId, UserEmail);
        setContainers(response?.response_object?.containers?.container);
        setSelectedAccount(accountId);
        setLoadingActive(false);

    }
    

    // get all accounts for the user
    useEffect(() => {
        (async () => {
            setLoadingActive(true); 
            // Send a request to check if the session is active
            const response = await GTMServiceAPI.GetAccounts(UserEmail);
            setAccounts(response?.response_object?.accounts?.account);
            setLoadingActive(false);

        })();
    },[])


    // set the current step accordingly
    useEffect(() =>{

        if(!authorizedGTM){
            setCurrent(0);
        }
        else if (authorizedGTM && accounts?.length > 0 && containers?.length > 0 && Object.keys(selectedContainer)?.length > 0 && tagsUpdated){
            setCurrent(4);
        }
        else if (authorizedGTM && accounts?.length > 0 && containers?.length > 0 && Object.keys(selectedContainer)?.length > 0){
            setCurrent(3);
        } 
        else if (authorizedGTM && accounts?.length > 0 && containers?.length > 0){
            setCurrent(2);
        } 
        else if (authorizedGTM && accounts?.length > 0 && !containers?.length > 0){
            setCurrent(1);
        } 


    }, [authorizedGTM, accounts, containers, 
        current, selectedContainer, tagsUpdated]
    );


    
    useEffect(() => {
        setShowMessage(false);
    }, [selectedContainer, selectedAccount]);


    return(
        
        <div>
            <LoadingScreen isActive={loadingActive} />
            <FullLoadingScreen isActive={loadingWithTextActive} />
            <ConfigProvider
                theme={{
                    components: {
                        Steps: {
                            colorPrimary: 'rgba(255, 189, 136,1)', 
                            processIconColor: 'rgba(255, 189, 136,1)',
                        },
                    },
                }}
                >
                <Steps 
                    current={current}      
                    direction="vertical"
                >
                    <Step
                        title="Google authentication"
                        description={
                            <div>
                                <p style={{ paddingRight: "35rem" }}>
                                    To automate changes to your Tag Manager container, please authenticate your Google account. 
                                    Click the button <br></br> below and follow the on-screen instructions provided by Google.
                                </p>

                                <div className={authorizedGTM ? `disabled-div` : ''}>
                                    <div className="btn btn-sm cc-btn-outline-blue" >
                                        <a href={authUrl}>                    
                                            Start authentication
                                        </a>
                                    </div>
                                </div>
                                <div style={{ height: "3rem", opacity: 1 }}></div>
                            </div>

                        }
                    />
                    <Step
                        title="Select an account"
                        description={
                            <div className='row mb-5'>
                            <div className={( accounts?.length >0  ) ? `` : 'disabled-div'}>
                                <p>
                                    This can be both your client- and server-side container.
                                </p>
            
                                <Select
                                    style={{ width : 200 }}
                                    showSearch
                                    placeholder="Select an account"
                                    optionFilterProp="label"
                                    onChange={(e) => {HandleSelectAccount(e)}}
                                    filterOption={true}
                                    disabled={!(accounts?.length >0 )}
                                    options={accounts?.map((account) => {return {value: account.accountId, label : account.name}})}
                                />
                            </div>
                        </div>
            
                        }
                    />
                    <Step
                        title="Select a container"
                        description={
                            <div className='row mb-5'>
                            <div className={containers && containers?.length > 0  ? `` : 'disabled-div'}>
                                <p>
                                    This can be both your client- and server-side container.
                                </p>
                    
                                <Select
                                    style={{ width : 200 }}
                                    showSearch
                                    placeholder="Select a container"
                                    optionFilterProp="label"
                                    onChange={(e) => HandleSelectContainer(e)}
                                    disabled={!(containers && containers?.length >0 )}
                                    filterOption={true}
                                    options={containers?.map((container) => {return {value: container.containerId , label : container.name}})}
                                />
                            </div>
                        </div>
                        }
                    />

                    <Step
                        title="Run update"
                        description={

                            <div className={authorizedGTM  ? `` : 'disabled-div'}>
                                <p>Click the button below to update the selected container.</p>
                                <div className="btn btn-sm cc-btn-blue" onClick={handleRunUpdate}>
                                    Run update
                                </div>
                            </div>
                                
                            
                        }
                    />
                </Steps>


                { (tagsUpdated && showMessage) && (
                    <Space direction="vertical" style={{ width: '40rem' , marginTop: "2rem"}}>
                        <Alert
                            message={`${selectedContainer.name} workspace successfully updated!`}
                            description={successMessage}
                            type="success"
                            closable
                        />
                    </Space>
                )}

                { (errorMsg && showMessage) && (
                    <Space direction="vertical" style={{ width: '40rem' , marginTop: "2rem"}}>
                        <Alert
                            message={`Error occurred while trying to update ${selectedContainer.name} workspace!`}
                            description={errorMsg}
                            type="error"
                            closable
                        />
                    </Space>
                ) }

            </ConfigProvider>
 
        </div>
    )

}

export default AuthorizedGTM;
