import FetchService from './fetch.service';

export const createDataset = async (reqData) => {
    const fetchService = new FetchService();

    const response = await fetchService("/create_dataset/", {
        method: 'POST',
        cache: 'no-cache',
        mode: 'cors',
        body: JSON.stringify(reqData),
        headers: {
            'Accept': 'application/json',
            "Content-Type": 'application/json',
        },
        credentials: 'include',
    });

    const resJson = await response.json();


    return resJson;
}
