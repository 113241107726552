import { React, useState, useEffect, createContext, useContext } from 'react';
import { CardChecklist, Envelope, Slack, MicrosoftTeams, Tools } from 'react-bootstrap-icons';

import ConfigService from '../../services/config.service';
import ProductSettingsService from '../../services/product.service';
import { AuthContext } from '../../services/auth.service';
import LoadingScreen from '../../components/elements/LoadingScreen';

import "./styles.scss";

import PageHeader from '../../components/elements/PageHeader';
import { SideMenu } from '../../components/layouts/MainLayout/SideMenu';
import { InteractivePanel } from "../../components/layouts/MainLayout/InteractivePanel";
import { EmailsNotifModal } from '../../components/layouts/MainLayout/SideMenu/EmailNotifModal';
import { SlackNotifModal } from '../../components/layouts/MainLayout/SideMenu/SlackNotifModal';
import { TeamsNotifModal } from '../../components/layouts/MainLayout/SideMenu/TeamsNotifModal';

import {
    ProductSettingsModal,
    MonitoringModal,
} from "./configModals";

import { useMessage } from "../../components/elements/MessageCenter";

// Save function
export const SaveConfigData = async (params) => {
    const {
        companyId,
        currentConfig,
        setCurrentConfig,
        configParams,
        modalSaveFunction,
        handlePanelClick,
        setLoadingActive,
        success,
        error,
    } = params;

    setLoadingActive(true);

    const configObj = { ...currentConfig };

    Object.keys(configParams).forEach(key => {
        configObj[key] = configParams[key];
    });

    const resp = await modalSaveFunction({ configObj, companyId });
    const respData = await resp?.json() || resp;

    switch (respData?.result) {
        case true:
            success("Configuration data successfully saved!");
            setCurrentConfig(configObj);
            break;
        default:
            error();
    }

    handlePanelClick("empty");
    setLoadingActive(false);
}

export const ProductSettingsContext = createContext();
export const MonitoringContext = createContext();
export const EmailsNotifContext = createContext();
export const SlackNotifContext = createContext();
export const TeamsNotifContext = createContext();

const productDescLink = `https://docs.code-cube.io/site-speed-monitoring/site_speed/`;
const notifImplementationLink = `https://docs.code-cube.io/notifications/`;

export const SitespeedMonitorConfig = () => {
    const { companyId, companyInfo } = useContext(AuthContext);
    
    // Using popup messages
    const { success, warning, error } = useMessage();

    // Modal window
    const [sideMenuState, setSideMenuState] = useState(false);
    const [currentModal, setCurrentModal] = useState(<></>);

    const [loadingActive, setLoadingActive] = useState(true);

    // Config data
    const [currentConfig, setCurrentConfig] = useState({});
    const [configParams, setConfigParams] = useState({});

    const [emailNotifAvailable, setEmailNotifAvailable] = useState(false);
    const [slackNotifAvailable, setSlackNotifAvailable] = useState(false);
    const [teamsNotifAvailable, setTeamsNotifAvailable] = useState(false);

    const [timezones, setTimezones] = useState([]);
    const [curTimezone, setCurTimezone] = useState(null);
    const [checkFreq, setCheckFreq] = useState([]);
    const [curCheckFreq, setCurCheckFreq] = useState(null);

    const [urlsList, setUrlsList] = useState([]);

    const [enableEmailNotif, setEnableEmailNotif] = useState(false);
    const [curEmailsList, setCurEmailsList] = useState([]);

    const [enableSlackNotif, setEnableSlackNotif] = useState(false);
    const [slackChannel, setSlackChannel] = useState("");
    const [slackWorkspaceId, setSlackWorkspaceId] = useState("");
    const [slackWebhook, setSlackWebhook] = useState("");

    const [enableTeamsNotif, setEnableTeamsNotif] = useState(false);
    const [teamsChannel, setTeamsChannel] = useState("");
    const [teamsWebhook, setTeamsWebhook] = useState("");

    useEffect(() => {
        (async () => {
            setLoadingActive(true);

            setSideMenuState(false);

            const resp = await ConfigService.getSitespeedMonitorConfigData(companyId);
            const respData = await resp.json();

            setCurrentConfig(respData?.config_data?.current_config);

            // Product settings data
            setTimezones(respData?.config_data?.timezones || []);
            setCurTimezone(respData?.config_data?.current_config?.timezone || "");
            setCheckFreq(respData?.config_data?.check_freq || "");
            setCurCheckFreq(respData?.config_data?.current_config?.times_per_day || "");

            // Monitoring of pages
            const urlsStr = respData?.config_data?.current_config?.urls || "{}";
            const urlsList = JSON.parse(unescape(urlsStr))?.events;
            setUrlsList(urlsList);

            // Email notifications data
            setEnableEmailNotif(respData?.config_data?.current_config?.email_updates || false);
            setCurEmailsList(respData?.config_data?.current_config?.emails?.split(";") || []);

            // Slack notifications data
            setEnableSlackNotif(respData?.config_data?.current_config?.slack_updates || false);
            setSlackChannel(respData?.config_data?.current_config?.slack_channel || "");
            setSlackWorkspaceId(respData?.config_data?.current_config?.slack_workspace_id || "");
            setSlackWebhook(respData?.config_data?.slack_webhook || "");

            // Teams notifications data
            setEnableTeamsNotif(respData?.config_data?.current_config?.teams_updates || false);
            setTeamsChannel(respData?.config_data?.current_config?.teams_channel || "");
            setTeamsWebhook(respData?.config_data?.current_config?.teams_webhook || "");

            const subLevel = companyInfo?.sitespeed_monitor_subscription_model?.toLowerCase();
            const settings = await ProductSettingsService.getProductSettings();
            const subSettings = settings?.sitespeed_monitor_settings[subLevel];
            setEmailNotifAvailable(subSettings?.email_notif);
            setSlackNotifAvailable(subSettings?.slack_notif);
            setTeamsNotifAvailable(subSettings?.teams_notif);

            setLoadingActive(false);
        })();
    }, [companyInfo]);

    const modalFunc = {
        "empty": <></>,
        "general":
            <ProductSettingsContext.Provider
                value={{
                    productDescLink,
                    timezones,
                    curTimezone,
                    checkFreq,
                    curCheckFreq,
                    setCurTimezone,
                    setCurCheckFreq,
                    setConfigParams,
                }}>
                <ProductSettingsModal context={ProductSettingsContext} />
            </ProductSettingsContext.Provider>,
        "pages":
            <MonitoringContext.Provider
                value={{
                    productDescLink,
                    urlsList,
                    setUrlsList,
                    setConfigParams,
                }}>
                <MonitoringModal context={MonitoringContext} />
            </MonitoringContext.Provider>,

        "email_notif":
            <EmailsNotifContext.Provider value={{
                notifImplementationLink,
                enableEmailNotif: currentConfig?.email_updates || false,
                curEmailsList: currentConfig?.emails?.split(";") || [],
                setConfigParams,
            }}>
                <EmailsNotifModal context={EmailsNotifContext} />
            </EmailsNotifContext.Provider>,
        "slack_notif":
            <SlackNotifContext.Provider value={{
                notifImplementationLink,
                enableSlackNotif: currentConfig?.slack_updates || false,
                slackChannel: currentConfig?.slack_channel || "",
                slackWorkspaceId: currentConfig?.slack_workspace_id || "",
                slackWebhook: currentConfig?.slack_webhook || "",
                setConfigParams,
            }}>
                <SlackNotifModal context={SlackNotifContext} />
            </SlackNotifContext.Provider>,
        "teams_notif":
            <TeamsNotifContext.Provider value={{
                notifImplementationLink,
                enableTeamsNotif: currentConfig?.teams_updates || false,
                teamsChannel: currentConfig?.teams_channel || "",
                teamsWebhook: currentConfig?.teams_webhook || "",
                setConfigParams,
            }}>
                <TeamsNotifModal context={TeamsNotifContext} />
            </TeamsNotifContext.Provider>,
    }

    const handlePanelClick = (page) => {
        setSideMenuState(!sideMenuState);
        setCurrentModal(modalFunc[page]);
    }

    return (
        <>
            <SideMenu
                globalState={sideMenuState}
                setGlobalState={setSideMenuState}
                modalContent={currentModal}
                popoverMessage={<>No changes to save.</>}
                saveFunction={() => SaveConfigData({
                    companyId,
                    currentConfig,
                    setCurrentConfig,
                    configParams,
                    modalSaveFunction: ConfigService.saveSitespeedMonitorConfigData,
                    handlePanelClick,
                    setLoadingActive,
                    success,
                    error,
                    warning,
                })}
            />
            <div className="row">
                <LoadingScreen isActive={loadingActive} />
                <div className="col-12 col-sm-10 col-md-8 text-start">
                    <div>
                        <div className="row">
                            <PageHeader categoryName="configuration" pageName="Sitespeed Monitor" />
                            <div className="container-rows mt-4">
                                <div>
                                    <InteractivePanel
                                        icon={<Tools size={21} />}
                                        headerText="Product settings"
                                        descriptionText="Set up checkup frequency and get help with setting up the product"
                                        available={true}
                                        clickFunction={() => handlePanelClick("general")}
                                    />
                                </div>
                                <div>
                                    <InteractivePanel
                                        icon={<CardChecklist size={21} />}
                                        headerText="Monitoring of pages"
                                        descriptionText="Update, add or delete pages where you want to monitor the site performance"
                                        available={true}
                                        clickFunction={() => handlePanelClick("pages")}
                                    />
                                </div>
                            </div>
                            <h3 className="page-category">Notifications</h3>
                            <div className="container-rows">
                                <div>
                                    <InteractivePanel
                                        icon={<Envelope size={21} />}
                                        headerText="Email Notifications"
                                        descriptionText="Configure e-mail addresses for mailing notifications"
                                        available={emailNotifAvailable}
                                        clickFunction={() => handlePanelClick("email_notif")}
                                    />
                                </div>
                                <div>
                                    <InteractivePanel
                                        icon={<Slack size={21} />}
                                        headerText="Slack notifications"
                                        descriptionText="Set up your Slack environment to receive notifications"
                                        available={slackNotifAvailable}
                                        clickFunction={() => handlePanelClick("slack_notif")}
                                    />
                                </div>
                                <div>
                                    <InteractivePanel
                                        icon={<MicrosoftTeams size={21} />}
                                        headerText="Teams notifications"
                                        descriptionText="Use your Microsoft Teams environment to receive notifications"
                                        available={teamsNotifAvailable}
                                        clickFunction={() => handlePanelClick("teams_notif")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
