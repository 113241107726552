import { React, useState, useEffect, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FiletypeDoc, Slack, InfoCircleFill } from 'react-bootstrap-icons';
import { Input, Switch, Flex } from 'antd';

import colors from '../../../../../styles/_colors.scss';

export const SlackNotifModal = ({ context }) => {
    const {
        notifImplementationLink,
        enableSlackNotif,
        slackChannel,
        slackWorkspaceId,
        slackWebhook,
        setConfigParams,
        setModalSaveStatus = () => {},
    } = useContext(context);

    const [notifStatus, setNotifStatus] = useState(enableSlackNotif);
    const [channel, setChannel] = useState(slackChannel);
    const [workspaceId, setWorkspaceId] = useState(slackWorkspaceId);
    const [configParamsToSave, setConfigParamsToSave] = useState({});

    const [ showGuideDiv, setShowGuideDiv ] = useState(false);

    const initNotifStatus = useRef([]);
    const initChannel = useRef([]);
    const initWorkspaceId = useRef([]);

    useEffect(() => {
        setNotifStatus(enableSlackNotif);
        setChannel(slackChannel);
        setWorkspaceId(slackWorkspaceId);
    }, [enableSlackNotif, slackChannel, slackWorkspaceId]);

    const handleNotifStatusChange = () => {
        setNotifStatus(!notifStatus);
    }
    const handleChannelChange = (event) => {
        setChannel(event.target.value);
    }
    const handleWorkspaceIdChange = (event) => {
        setWorkspaceId(event.target.value);
    }

    useEffect(() => {
        const newObject = { ...configParamsToSave };
        newObject.slack_updates = notifStatus;
        newObject.slack_channel = channel;
        newObject.slack_workspace_id = workspaceId;
        setConfigParamsToSave(newObject);

        if (!Object.keys(initNotifStatus).find(elem => elem === "current") || initNotifStatus.current.length === 0) 
            initNotifStatus.current = notifStatus;
        if (!initChannel.current.length && initChannel.current !== "") initChannel.current = channel;
        if (!initWorkspaceId.current.length && initWorkspaceId.current !== "") initWorkspaceId.current = workspaceId;

        setModalSaveStatus(
            initNotifStatus.current !== notifStatus ||
            initChannel.current !== channel ||
            initWorkspaceId.current !== workspaceId
        );
    }, [notifStatus, channel, workspaceId]);

    useEffect(() => setConfigParams(configParamsToSave), [configParamsToSave]);

    return(
        <div className="modal-elems-rows">
            <div>
                <h5 className='small-heading'>CONFIGURATION</h5>
                <h2>Slack notifications</h2>
            </div>
            <div className="container-guide modal-panel-button-row">
                <div>
                    <Link className="panel-button-container grey-bg" target="_blank" to={notifImplementationLink}>
                        <div className="file-icon">
                            <FiletypeDoc style={{ color: colors.ccBlue }} size={22} />
                        </div>
                        <div className="panel-button-text">
                            <div className="panel-button-header">Implementation guide</div>
                            <div className="panel-button-desc">Guide how to set up Slack notifications</div>
                        </div>
                    </Link>
                </div>
            </div>

            <Flex align="center" gap={6}>
                <Switch size="small" checked={notifStatus} onChange={handleNotifStatusChange} />
                <>Enable Slack notifications</>
            </Flex>
            <div style={{ position: 'relative' }}>
                {
                    showGuideDiv && 
                    <div className='hoverable-div-parent' style={{ left: '10em' }}>
                        <p className='hover-content'>
                            Check out our easy-to-follow guides at the top of 
                            the page to ensure successful configuration and setup of Slack notifications
                        </p>
                    </div>
                }
                <h3 className="modal-category mb-0">
                    Account details
                    <InfoCircleFill 
                        size={15} 
                        className='info-icon' 
                        onMouseEnter={() => setShowGuideDiv(true)}
                        onMouseLeave={() => setShowGuideDiv(false)}
                    />
                </h3>
                <p>
                    Add the workspace ID of your Slack account below.
                </p>
                <Input className='wide-input' type="text" placeholder="codecube.slack.com" value={workspaceId} onChange={handleWorkspaceIdChange}/>
            </div>
            <div>
                <p className='mb-2'>
                    Add the name of the Slack channel where you would 
                    like to receive your notifications.
                </p>
                <Input className='wide-input' type="text" placeholder="code-cube-alerts" value={channel} onChange={handleChannelChange} />
            </div>
            <div className="container-rows modal-panel-button-row">
                <div className='mt-3'>
                    <Link className="panel-button-container" target="_blank" to={slackWebhook.replace("$slack_workspace_id$", workspaceId)}>
                        <div className="panel-button-icon">
                            <Slack size={22} />
                        </div>
                        <div className="panel-button-text">
                            <div className="panel-button-header">Connect your workspace</div>
                            <div className="panel-button-desc">Allow our slack bot to connect with your workspace to send you updates</div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}
